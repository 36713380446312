import React, { useMemo } from "react";
import { NavParent, SidebarProps, useNavdata } from "./NavConfig";
import MiniNavbar from "./MiniNavbar";
import MainNavbar from "./MainNavbar";
import { Box } from "@mui/material";

const Sidebar: React.FC<SidebarProps> = ({ isOpen, handleCollapseToggle }) => {
    const navData: NavParent[] = useNavdata();

    //memoized Main Navbar
    const memoizedMainNav = useMemo(
        () => (
            <MainNavbar
                navData={navData}
                handleCollapseToggle={handleCollapseToggle}
                isOpen={isOpen}
            />
        ),
        [navData, handleCollapseToggle, isOpen]
    );

    //memoized Mini Navbar
    const memoizedMiniNav = useMemo(
        () => (
            <MiniNavbar
                navData={navData}
                handleCollapseToggle={handleCollapseToggle}
                isOpen={isOpen}
            />
        ),
        [navData, handleCollapseToggle, isOpen]
    );

    // if (isOpen) {
    //     return <>{memoizedMainNav}</>;
    // }

    // return <>{memoizedMiniNav}</>;
    return <Box sx={{width: isOpen ? '380px': '110px', transition:'width 0.3s ease' }}>
        {isOpen ? memoizedMainNav :  memoizedMiniNav}
    </Box>
};

export default Sidebar;
