import { Box, Typography } from "@mui/material";

interface AnalyticsCardProps {
  title: string;
  value: number;
  icon?: any;
}
const AnalyticsCard: React.FC<AnalyticsCardProps> = ({title, value,icon}) => {
  return (
    <Box sx={{backgroundColor:"rgb(233, 234, 244)", borderRadius: '8px', height: '100px', alignItems: 'center', gap: '20px', display: 'flex', justifyContent: 'center', padding: '10px',    boxShadow: "rgba(145, 158, 171, 0.24) 0px 1px 2px 0px"}}>
      {icon}
      <Typography variant="body1" sx={{color: 'primary.dark', fontWeight: 700}}>
        {value ? value : 0} <br />{title}
      </Typography>
    </Box>
  )
}

export default AnalyticsCard;