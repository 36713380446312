import { createTheme } from "@mui/material";

interface customColors {
  'gray-50': string;
  'gray-100': string;
  white: string;
  'gray-200': string;
  'blue-600':string;
  'blue-700':string;
  'purple-200':string;
}
declare module '@mui/material/styles' {
  interface Palette {
    customColors?: customColors;
  }
  interface PaletteOptions {
    customColors?: customColors;
  }
}

const Theme = createTheme({
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
      styleOverrides: {
        root: {
          fontFamily: "Inter",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontFamily: "Inter",
          textTransform: "none",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#4F4D9E",
      light: "#4F4D9E",
      dark: "#37366F",
    },
    secondary: {
      main: "#212B36",
    },
    text: {
      primary: "#212B36",
      secondary: "#FFFFFF",
    },
    background:{
      "default":"#919EAB3D",
    },
    customColors:{
      'gray-50':"#919EAB14",
      'gray-100': "#F9F9FB",
      white: "#FFFFFF",
      'gray-200':"#637381",
      'blue-600':"#1F2991",
      'blue-700':"#161D66",
      'purple-200':"#1F299129",
    }
  },
  typography: {
    fontFamily: "Inter",
  },
});

export default Theme;
