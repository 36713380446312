import { Box, Button, CircularProgress } from "@mui/material";
import { downLoadBSAReport } from "../../api/apiCalls";
import { useState } from "react";
import { notify } from "../../context/notificationService";
import { useDispatch } from "react-redux";
import { updateReportStatus } from "../../redux/slices/allAnalysisSlice";

interface CheckReportStatusButtonProps {
  reportId: string;
}
const CheckReportStatusButton: React.FC<CheckReportStatusButtonProps> = ({reportId}) => {
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false);
  const handleCheckReportStatus = async() => {
    setLoading(true);
    try {
      const resp = await downLoadBSAReport({reportId,fileType:'json and excel'});
      console.log("status check api",resp)
      if(resp.status){
        dispatch(updateReportStatus({reportId,status:resp.status}));
        notify(`Analysis ${resp.status} for ${reportId}`);
      }else if(resp.result){
        dispatch(updateReportStatus({reportId,status:"ANALYSED"}));
        notify(`Report is analysed for ${reportId}`,"success")
      }else{
        notify(`Error getting status for ${reportId}`,"error");
      }
    } catch (error) {
      console.log("error checking report status",error);
    }finally{
      setLoading(false);
    }
  }
  return (
    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      {loading ? <CircularProgress sx={{mr:'40px'}} size={20} /> :
      <Button sx={{borderRadius: '8px',color:"#99cc33"}} onClick={handleCheckReportStatus}>
        Check status
      </Button>
      }
    </Box>
  )
}

export default CheckReportStatusButton;