import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initiateAnalysisState {
  filesArray: {fileUrl: string, fileName: string, password: string,size: number}[];
  showModal: boolean;
  previewUrl: string;
  uploadingFiles: boolean;
  uploadingText:string;
}

const initialState: initiateAnalysisState = {
  filesArray: [],
  showModal: false,
  previewUrl: '',
  uploadingFiles:false,
  uploadingText: '',
}
const initiateAnalysis = createSlice({
  name: "initiateAnalysis",
  initialState,
  reducers: {
    setAnalysisFilesArray(state, action: PayloadAction<{fileUrl: string, fileName: string, password: string,size: number}[]>) {
      state.filesArray = action.payload;
    },
    clearAnalysisFilesArray(state) {
      state.filesArray = [];
    },
    setAnalysisFilePassWord(state, action: PayloadAction<{fileName: string, password: string}>) {
      const { fileName, password } = action.payload;
      const fileToUpdate = state.filesArray.find(file => file.fileName === fileName);

      if (fileToUpdate) {
        fileToUpdate.password = password;
      }
    },
    removeAnalysisFile(state, action: PayloadAction<string>) {
      state.filesArray = state.filesArray.filter(file => file.fileName !== action.payload);
    },
    showPreview(state, action: PayloadAction<{previewUrl: string}>) {
      state.previewUrl = action.payload.previewUrl;
      state.showModal = true;
    },
    closePreview(state) {
      state.showModal = false;
    },
    setUploadingFiles(state,action: PayloadAction<boolean>){
      state.uploadingFiles = action.payload;
    },
    setUploadingText(state, action:PayloadAction<string>){
      state.uploadingText = action.payload;
    }
  },
});

export const { setAnalysisFilesArray, clearAnalysisFilesArray, setAnalysisFilePassWord, removeAnalysisFile, showPreview, closePreview, setUploadingFiles, setUploadingText} = initiateAnalysis.actions;
export default initiateAnalysis.reducer;
