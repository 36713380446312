import React from "react";

interface RenderIfProps {
  condition: boolean;
  children: any;
}
export const RenderIf: React.FC<RenderIfProps> = ({ condition, children }) => {
  if (Boolean(condition)) return children;
  return <React.Fragment />;
};
