// FileDropzone.tsx
import React, { useCallback } from 'react';
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import { Typography, Paper } from '@mui/material';
import fileInputIcon from '../../assets/images/uploadImage.png';
// Define the props for better type safety
interface FileDropzoneProps {
  onDropAccepted: (files: File[]) => void;
  onDropRejected?: (rejections: FileRejection[]) => void;
}

const FileDropzone: React.FC<FileDropzoneProps> = ({
  onDropAccepted,
  onDropRejected,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      if (acceptedFiles.length) {
        onDropAccepted(acceptedFiles);
      }
      if (fileRejections.length && onDropRejected) {
        onDropRejected(fileRejections);
      }
    },
    [onDropAccepted, onDropRejected]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'application/pdf': ['.pdf'] }, // Accept only PDFs
    maxSize: 5 * 1024 * 1024, // 5MB max size
  });

  return (
    <Paper
      {...getRootProps()}
      sx={{
        padding: 3,
        border: "1px dashed rgba(145, 158, 171, 0.314)",
        borderRadius: 2,
        textAlign: 'center',
        backgroundColor: isDragActive ? '#f0f0f0' : "rgba(145, 158, 171, 0.08)",
        transition: 'background-color 0.3s ease',
        cursor: 'pointer',
        boxShadow:"none",
      }}
    >
      <input {...getInputProps()} />
      <img
        src={fileInputIcon}
        style={{ width: "200px", minHeight:"150px" }}
        alt={"Signzy"}
      />
      <Typography variant="body1" marginY={2} fontSize={18} fontWeight={700} color="black">
        {isDragActive ? 'Drop file here' : 'Drag a file here'}
      </Typography>
      <Typography variant="caption" fontSize={14} color='rgb(99, 115, 129)'>
        Drop files here or click to browse through your machine <br /> (You can upload multiple files) <br /> Acceptable formats: <span style={{ color: 'rgb(79, 77, 158)' }}>PDF</span>
      </Typography>
    </Paper>
  );
};

export default FileDropzone;
