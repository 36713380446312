import { Close, ExpandMore, Search, Sell } from "@mui/icons-material";
import { Box, Button, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useDispatch } from "react-redux";
import { clearTransactionsFilter, openTransactionsFilter, setAppliedTransactionsFilter, setTransactionsData, setTransactionsFilter, setTransactionsFilterList } from "../../redux/slices/reportSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { getAllTransactions, getTransactionsFilters } from "../../api/apiCalls";
import { useEffect } from "react";

const TransactionsFilter = ()=>{
  const reportId = useSelector((state:RootState)=>state.report.reportId);
  const transactionsReady = useSelector((state:RootState)=>state.report.transactionsReady);
  const categories = useSelector((state:RootState)=>state.report.transactions.categories);
  const counterparties = useSelector((state:RootState)=>state.report.transactions.counterparties);
  const tags = useSelector((state:RootState)=>state.report.transactions.tags);
  const filters = useSelector((state:RootState)=>state.report.transactions.filters);
  const appliedFilters = useSelector((state:RootState)=>state.report.transactions.appliedFilters);

  const dispatch = useDispatch();


  const handleClearFilters = ()=>{
    dispatch(clearTransactionsFilter());
  }
  const handleApplyFilters = async()=>{
    try {
      const data = await getAllTransactions({reportId:reportId,...filters,limit:appliedFilters.limit,page:1});
      dispatch(setTransactionsData(data));
      dispatch(setAppliedTransactionsFilter(filters));
      dispatch(openTransactionsFilter(false));
    } catch (error) {
      console.log("Error",error);
    }
  }

  const fetchFilterLists = async()=>{
    try {
      const resp = await getTransactionsFilters(reportId)
      dispatch(setTransactionsFilterList({counterparties:resp.counterparties,categories:resp.categories,tags:resp.tags}));
    } catch (error) {
      console.log("error",error)
    }
  }

  useEffect(()=>{
    fetchFilterLists();
  },[transactionsReady])

  return (<Box sx={{width:"400px", height:"100vh", overflowY:"auto", backgroundColor:"customColors.white", display:"flex",flexDirection:"column"}}>
    <Box sx={{backgroundColor:"background.default", flexGrow:1, display:"flex", flexDirection:"column",gap:"12px", padding:"20px"}}>
      <Box sx={{display:"flex", justifyContent:"space-between"}}>
        <Box sx={{display:"flex", alignItems:"center"}}>
          <Typography ml="8px" color="secondary.main" fontSize={18} fontWeight={700}>
            Filters
          </Typography>
            <Box sx={{ml:"8px", height:"20px", width:"20px", borderRadius:"100%",backgroundColor:"primary.main",color:"white", fontSize:"12px",alignItems:"center", display:"flex", justifyContent:"center"}}>
              {appliedFilters.count}
            </Box>
        </Box>
          <Close fontSize="small" onClick={()=>dispatch(openTransactionsFilter(false))} sx={{cursor:"pointer", color:"customColors.gray-200"}}/>
      </Box>

      <Box sx={{padding:"8px"}}>
        <Typography sx={{mb:"10px"}}>
          Category
        </Typography>
        <TextField
          select
          fullWidth
          value={filters.categories}
          onChange={(e)=>dispatch(setTransactionsFilter({categories: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}))}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment
              position="start"
              sx={{ position:"absolute", pointerEvents: "none", color: "gray", display:filters.categories.length ? "none":"flex" }}
            >
              <Search  sx={{mr:"8px"}} fontSize="medium"/>
              Search category
            </InputAdornment>
              ),
              "aria-label": "Search category",
            },
            select:{
              IconComponent: ExpandMore,
              displayEmpty:true,
              multiple:true,
              renderValue: (selected) =>
                (Array.isArray(selected) && selected.length > 0) && <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Box
                        key={value}
                        sx={{
                          padding: '2px 8px',
                          background: '#e0e0e0',
                          borderRadius: '8px',
                          color:"black"
                        }}
                      >
                        {value}
                      </Box>
                    ))}
                  </Box>,
              sx:{
                borderRadius:"8px",
              },
              MenuProps: {
                anchorOrigin: {
                  vertical: "top", // Align to the top
                  horizontal: "left", // Align to the left of the input box
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "right", // Offset from the right edge
                },
              },
            }
          }}
          >
          {categories.map((category,index)=>{
            return(<MenuItem value={category} key={index}>{category}</MenuItem>)
          })}
        </TextField>
      </Box>

      <Box sx={{padding:"8px"}}>
        <Typography sx={{mb:"10px"}}>
          Counterparty
        </Typography>
        <Select
          fullWidth
          multiple
          value={filters.counterparty}
          onChange={(e)=>dispatch(setTransactionsFilter({counterparty: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}))}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Box
                  key={value}
                  sx={{
                    padding: '2px 8px',
                    background: '#e0e0e0',
                    borderRadius: '8px',
                    color:"black"
                  }}
                >
                  {value}
                </Box>
              ))}
            </Box>
          )}
          IconComponent={() => null}
          sx={{borderRadius:"8px"}}
          MenuProps={{
            anchorOrigin: {
              vertical: "top", // Align to the top
              horizontal: "left", // Align to the left of the input box
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right", // Offset from the right edge
            },
          }}
        >
        {counterparties.map((counterparty,index)=>{
            return(<MenuItem value={counterparty} key={index}>{counterparty}</MenuItem>)
          })}
        </Select>
      </Box>

      <Box sx={{padding:"8px"}}>
        <Typography sx={{mb:"10px"}}>
          Tags
        </Typography>
        <TextField
          select
          fullWidth
          value={filters.tags}
          onChange={(e)=>dispatch(setTransactionsFilter({tags: typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value}))}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment
              position="start"
              sx={{ position:"absolute", pointerEvents: "none", color: "gray", display:filters.tags.length ? "none":"flex" }}
            >
              <Sell sx={{mr:"8px"}} fontSize="small"/>
              Select tags
            </InputAdornment>
              ),
              "aria-label": "Select tags",
            },
            select:{
              IconComponent: ExpandMore,
              multiple:true,
              displayEmpty:true,
              renderValue: (selected) =>
                (Array.isArray(selected) && selected.length > 0) && <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Box
                        key={value}
                        sx={{
                          padding: '2px 8px',
                          background: '#e0e0e0',
                          borderRadius: '8px',
                          color:"black"
                        }}
                      >
                        {value}
                      </Box>
                    ))}
                  </Box>,
                sx:{
                  borderRadius:"8px"
                },
                MenuProps: {
                  anchorOrigin: {
                    vertical: "top", // Align to the top
                    horizontal: "left", // Align to the left of the input box
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right", // Offset from the right edge
                  },
                },
            }
          }}
          >
          {tags.map((category,index)=>{
            return(<MenuItem value={category} key={index}>{category}</MenuItem>)
          })}
        </TextField>
      </Box>
    </Box>
    <Box sx={{display:"flex", justifyContent:"space-between", padding:"20px"}}>
      <Button sx={{fontWeight:"700",fontSize:"14px", lineHeight:"24px"}} onClick={handleClearFilters}>
        Clear filters
      </Button>
      <Button variant="contained" sx={{fontWeight:"700",fontSize:"14px", lineHeight:"24px"}} onClick={handleApplyFilters}>
        Apply filters
      </Button>
    </Box>
  </Box>)
}

export default TransactionsFilter;