import { Add, WarningRounded } from "@mui/icons-material";
import { Box, Button, CircularProgress, Modal, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { addUser, getUsersList } from "../../api/apiCalls";
import { notify } from "../../context/notificationService";
import UserSettingsRow from "./UserSettingsRow";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { useDispatch } from "react-redux";
import { setLoadingUsersList, setUsersList } from "../../redux/slices/userManagementSlice";

const rows = Array.from({ length: 8 });
const columns = Array.from({ length: 5 });
const UserSettings = ()=>{
  const user = useSelector((state:RootState)=>state.user);
  const usersList = useSelector((state:RootState)=> state.userManagement.usersList);
  const loadingUsers = useSelector((state:RootState)=>state.userManagement.loadingUsersList);
  const dispatch = useDispatch();
  const [openAddUserModal,setOpenAddUserModal] = useState(false);
  const [addingUser, setAddingUser]= useState(false);
  const [username,setUsername] = useState("");
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const validate = (fieldsToValidate: string[]) => {
    let errors = [...formErrors];
    if (fieldsToValidate.includes('username')) {
      const usernameError = validateUsername(username);
      errors = errors.filter(error => !error.includes('Username'));
      if (usernameError) {
        errors.push(usernameError);
      }
    }
    setFormErrors(errors);
    return errors.length === 0;
  };

  const validateUsername = (username: string) => {
    if (!username) {
      return 'Username is required';
    }
    if (!(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(username))) {
      return 'Username must be a valid email address';
    }
    return null;
  };

  const handleModalClose = ()=>{
    setOpenAddUserModal(false);
    setUsername("");
    setFormErrors([]);
  }

  const handleAddUser= async(username:string)=>{
    try {
      const validationError = validateUsername(username)
      if(validationError){
        notify(validationError,"warning");
        return;
      }
      setAddingUser(true);
      const resp = await addUser(username);
      handleModalClose();
      notify(resp.message,"success");
      fetchUsersList();
    } catch (error) {
      console.log("error",error);
    }finally{
      setAddingUser(false);
    }
  }

  const fetchUsersList = async()=>{
    try {
      dispatch(setLoadingUsersList(true));
      const users = await getUsersList();
      dispatch(setUsersList(users));
    } catch (error) {
      console.log("error",error);
    }finally{
      dispatch(setLoadingUsersList(false));
    }
  }

  useEffect(()=>{
    fetchUsersList();
  },[])

  return (
    <Box>
      <Modal
        open={openAddUserModal}
        onClose={handleModalClose}
        sx={{display:"flex", height:"100vh", width:"100vw", alignItems:"center",justifyContent:"center"}}
      >
        <Box sx={{backgroundColor:"customColors.white", padding:"20px", borderRadius:"12px"}}>
          <Typography variant="h4" fontSize={24} fontWeight={600} mb="12px">Add User</Typography>
        <Box sx={{display:"grid", gridTemplateColumns:"1fr", gap:"20px",width:"600px"}}>
          <TextField fullWidth label="username" variant="outlined" name="username" value={username} type="email" sx={{label: { color: "#919EAB" }}}
          error={formErrors.some(error => error.includes('Username'))}
          onChange={(e)=>setUsername(e.target.value)}
          onBlur={() => validate(['username'])}
          />
        </Box>
        <Box>
        {formErrors.length > 0 && (
        <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center',mt:"12px"}}>
          <WarningRounded sx={{color: '#D76662'}} fontSize="small"/>
          <Typography sx={{ color: '#D76662', fontSize: '12px' }}>
            {formErrors.join(', ') + '.'}
          </Typography>
        </Box>
      )}
        </Box>
        <Box sx={{display:"flex", justifyContent:"flex-end", mt:"12px",gap:"12px"}}>
          <Button sx={{fontWeight:600}} onClick={handleModalClose}>
            Cancel
          </Button>
          <Button startIcon={addingUser && <CircularProgress size={25}/>} sx={{fontWeight:600}} variant="contained" disabled={(username && formErrors.length === 0) && !addingUser ? false : true} onClick={()=>handleAddUser(username)}>
            Add
          </Button>
        </Box>
        </Box>
      </Modal>
      <Paper sx={{padding:"20px", borderRadius:"12px", mt:"14px"}}>
        <Box sx={{display:"flex", justifyContent:"space-between"}}>
          <Typography>
            <span style={{color:"#4F4D9E"}}>Logged in as:</span> {user.username}
          </Typography>
          <Button variant="outlined" startIcon={<Add />} onClick={()=>setOpenAddUserModal(true)}>
            Add Users
          </Button>
        </Box>
      </Paper>
      <Paper sx={{mt:"14px",borderRadius:"12px",padding:"20px"}}>
      <TableContainer sx={{height:"70vh", overflow:"scroll",borderTop:"1px solid", borderColor: 'divider'}}>
            <Table stickyHeader>
              <TableHead sx={{"& .MuiTableCell-head":{
                color:"customColors.gray-200",
                fontWeight:600,
                fontSize:"14px",
                lineHeight:"24px",
                whiteSpace:"nowrap",
                backgroundColor:"#919EAB14"
              }}}>
                <TableRow>
                  <TableCell>
                    Username
                  </TableCell>
                  <TableCell>
                    Role
                  </TableCell>
                  <TableCell>
                    Created On
                  </TableCell>
                  <TableCell>
                    Verification
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{backgroundColor:"customColors.white",'& .MuiTableRow-root:nth-of-type(even)':{
                backgroundColor:"#919EAB14"
              }}}>
                { loadingUsers ? (
                  rows.map((row,index) => (
                    <TableRow key={index}>
                      {columns.map((column,columnIdx)=>(
                      <TableCell key={columnIdx}>
                        <Skeleton />
                      </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  usersList.length > 0  ?
                  usersList.map((user,index) => ( <UserSettingsRow {...user} key={index}/>
                  )) : <TableRow>
                  <TableCell colSpan={11} sx={{ border: "none" }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" , fontSize:"24px"}}>
                      Something went wrong!
                    </Box>
                  </TableCell>
                </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
      </Paper>
    </Box>
  )
}

export default UserSettings;