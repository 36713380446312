import { Box, Button, Checkbox, CircularProgress, FormControlLabel, TextField, Typography } from "@mui/material";
import PasswordField from "../components/PasswordField";
import { ChangeEvent, FormEvent, useState } from "react";
import { WarningRounded } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { loginApi, sendResetPasswordLink } from "../api/apiCalls";
import { setPassWord, setRememberUser, setUser } from "../redux/slices/userSlice";
import { Navigate } from "react-router-dom";
import { RootState } from "../redux/rootReducer";
import FullScreenLoader from "../components/loaders/FullScreenLoader";
import { useSnackbar } from "../context/SnackbarProvider";
import { setLoginFormError, setLoginFormState } from "../redux/slices/formStateSlice";
import SignzyLogo from "../assets/images/signzyLogo.png";
import LoginPageDispImg from "../assets/images/loginPageDispImg.png";

const Login = () => {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const { showNotification } = useSnackbar();

  const [showLoginForm, setShowLoginForm] = useState(true);
  const [loading, setLoading] = useState(false);
  if(user && user.token !== ''){
    return <Navigate to="/dashboard" replace />
  }

  // Function to validate username
  const validateUsername = (username: string) => {
    if (!username) {
      return 'Username is required';
    }
    // Example: Change this regex if you need a different validation rule for username
    if (!(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(username))) {
      return 'Username must be a valid email address';
    }
    return null; // No error
  };

  // Function to validate password
  const validatePassword = (password: string) => {
    if (!password) {
      return 'Password is required';
    } else if (password.length < 6) {
      return 'Password must be at least 6 characters';
    }
    return null; // No error
  }

  const LoginForm = () => {
    const loginForm = useSelector((state:RootState)=>state.forms.loginForm);
    const formErrors = useSelector((state:RootState)=>state.forms.loginFormErrors);
    const rememberMe = useSelector((state:RootState)=>state.forms.loginForm.rememberMe);
    const [formValues, setFormValues] = useState({ username: user.username || loginForm.username, password: user.password || loginForm.password});
    // Handle input changes dynamically
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFormValues({ ...formValues, [name]: value });
      dispatch(setLoginFormState({[name]:value}));
    };

      // Validate form inputs
      const validate = (fieldsToValidate: string[]) => {
        let errors = [...formErrors]; // Initialize errors as an array

        // Validate each field based on the fields passed to the function
        if (fieldsToValidate.includes('username')) {
          const usernameError = validateUsername(formValues.username);
          errors = errors.filter(error => !error.includes('Username'));
          if (usernameError) {
            errors.push(usernameError);
          }
        }

        if (fieldsToValidate.includes('password')) {
          const passwordError = validatePassword(formValues.password);
          errors = errors.filter(error => !error.includes('Password'));
          if (passwordError) {
            errors.push(passwordError);
          }
        }

        dispatch(setLoginFormError(errors)); // Set error messages
        return errors.length === 0; // Return true if no errors
      };


    // Handle form submission
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if(!validate(['username', 'password'])) {
        return;
      };
      setLoading(true);
      try {
        const resp = await loginApi(formValues.username,formValues.password);
        const {username, token} = resp.data;
        dispatch(setUser({username, token, password:'', expiryTime: Date.now() + 86350000 }));
        dispatch(setRememberUser(rememberMe));
        if(rememberMe){
          dispatch(setPassWord(formValues.password));
        }
        dispatch(setLoginFormState({username:'',password:'',rememberMe:true}));
        setFormValues({username:'',password:''});
        dispatch(setLoginFormError([]));
        showNotification('Logged in successfully !','success');
      } catch (error) {
        console.log("error login",error);
      } finally {
        setLoading(false);
      }
    }

  return(
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' , padding: '100px', gap: '24px'}}
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit}
      >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '16px'}}>
        <img src={SignzyLogo} alt="Signzy" style={{height:"66px"}}/>
      </Box>
      <Typography variant="h4" component="h1" sx={{color: 'secondary.main', fontWeight: 700, textAlign: 'left', fontSize:'32px', mb: '16px'}}>Log in to your account!</Typography>
      <TextField  label="username" variant="outlined" name="username" value={formValues.username} type="email" sx={{label: { color: "#919EAB" }, borderRadius: '12px'}}
        error={formErrors?.some(error => error.includes('Username'))}
        onChange={handleChange}
        onBlur={() => validate(['username'])}
      />
      <PasswordField  sx={{borderRadius: '8px'}}
        name="password" onChange={handleChange}
        error={formErrors?.some(error => error.includes('Password'))}
        value={formValues.password}
        onBlur={() => validate(['password'])}
      />
      {formErrors.length > 0 && (
        <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center'}}>
          <WarningRounded sx={{color: '#D76662'}} fontSize="small"/>
          <Typography sx={{ color: '#D76662', fontSize: '12px' }}>
            {formErrors?.join(', ') + '.'}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <FormControlLabel
              control={
                <Checkbox  name="rememberme" size="small" checked={rememberMe} sx={{color: 'secondary.main'}}/>
              }
              label="Remember me"
              onChange={()=> dispatch(setLoginFormState({rememberMe:!rememberMe}))}
          />
          <Button variant="text" sx={{color: 'primary.main', fontWeight: 700, textTransform: 'none'}} onClick={() => setShowLoginForm(false)}>Forgot password?</Button>
      </Box>
      <Button variant="contained" sx={{backgroundColor: 'primary', color: 'text.secondary', height: '48px', fontWeight: 700, textTransform: 'none'}} type="submit">Login</Button>
    </Box>
    )
  }

  const ForgotPasswordForm = () => {
    const [email,setEmail] = useState("");
    const [emailError,setEmailError] = useState("");
    const [loading,setLoading] = useState(false);
    const handleValidateEmail =()=>{
      const newErr = validateUsername(email);
      if(newErr){
        setEmailError(newErr);
      }else{
        setEmailError("");
      }
    }
    const handleResetPassword = async()=>{
      try {
        setLoading(true);
        const resp = await sendResetPasswordLink(email);
        showNotification(resp.message,"success");
      } catch (error) {
        console.log("error",error);
      }finally{
        setLoading(false);
      }
    }
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' , padding: '100px', gap: '24px'}}>
      <Typography variant="h4" component="h1" sx={{color: 'secondary.main', fontWeight: 700, textAlign: 'left', fontSize:'32px'}}>Forgot your password?</Typography>
      <Typography variant="body1" component="p" sx={{color: '#637381', fontWeight: 400, textAlign: 'left', fontSize:16}}>Please enter the email address associated with your account, and we'll email you a link to reset your password.</Typography>
      <TextField id="outlined-basic" label="Email address" variant="outlined" type="email" sx={{label: { color: "#919EAB" }}} onChange={(e)=>setEmail(e.target.value)} onBlur={handleValidateEmail} error={emailError.length > 0 ? true:false} />
      {emailError.length > 0 && <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center'}}>
          <WarningRounded sx={{color: '#D76662'}} fontSize="small"/>
          <Typography sx={{ color: '#D76662', fontSize: '12px' }}>
            {emailError}
          </Typography>
        </Box>}
      <Button variant="contained" startIcon={loading && <CircularProgress size={25}/>}
        sx={{backgroundColor: (emailError || !email) ? '#919EAB33' : "primary.main", color: (emailError || !email) ? '#919EABCC': "customColors.white", height: '48px', fontWeight: 700, textTransform: 'none'}} disabled={(!email || emailError || loading) ? true:false}
        onClick={handleResetPassword}
      >Reset Password</Button>
      <Button sx={{color: 'primary.main', fontWeight: 700, textTransform: 'none', fontSize: '16px', height: '48px'}} onClick={() => setShowLoginForm(true)}>Back</Button>
    </Box>
    )
  }
  return (<>{loading && <FullScreenLoader />}<Box sx={{ display: 'grid', height: '100vh', gridTemplateColumns: '1.5fr 1fr', }}>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems:'center', backgroundColor: 'primary.main' }}>
      <img src={LoginPageDispImg} alt="Bank Statement Analysis" style={{background: 'transparent', objectFit:'contain', width:'450px',height:'450px'}} />
    </Box>
    {showLoginForm ? <LoginForm /> : <ForgotPasswordForm />}
  </Box></>)

}

export default Login;