import { AlertColor } from "@mui/material";

type NotificationFunction = (msg: string, sev?: AlertColor) => void;
let showNotification: NotificationFunction = () => {};

export const setNotificationFunction = (fn: NotificationFunction) => {
  showNotification = fn;
};

export const notify = (msg: string, sev: AlertColor = "info") => {
  showNotification(msg, sev);
};