import { Box, Button, Typography } from "@mui/material";
import PasswordField from "./PasswordField";
import { Delete, PictureAsPdf } from "@mui/icons-material";
import { useState } from "react";
import { removeAnalysisFile, setAnalysisFilePassWord, showPreview } from "../redux/slices/initiateAnalysisSlice";
import { useDispatch } from "react-redux";
import { useSnackbar } from "../context/SnackbarProvider";

interface EditAnalysisFilePasswordProps {
  file: string;
  fileName: string;
  password: string;
  size: number;
}

const EditAnalysisFilePassword: React.FC<EditAnalysisFilePasswordProps> = ({file,fileName,password,size}) => {
  // const [disabled,setDisabled] = useState(true);
  const [newPassword,setNewPassword] = useState(password);
  const dispatch = useDispatch();
  const {showNotification} = useSnackbar();

  const handleRemoveFile = (name: string) => {
    dispatch(removeAnalysisFile(name));
  }
  const handlePreview = (fileUrl: string) => {
    dispatch(showPreview({previewUrl: fileUrl}));
    }

  const handleSave = () => {
    if(newPassword !== password) {
      dispatch(setAnalysisFilePassWord({fileName, password: newPassword}));
      showNotification(`password updated for file: ${fileName}`,'info');
    }
    // setDisabled(true);
  }

  return (<Box key={fileName} sx={{border:'1px solid rgba(145, 158, 171, 0.2)', borderRadius: '8px', p: 2, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', alignItems: 'center'}}>
    <Box sx={{display: 'flex', gap: '10px', cursor: 'pointer'}} onClick={() => handlePreview(file)}>{fileName} <PictureAsPdf color="error" /></Box>
    <Typography>{Math.round(size / 1024)} KB</Typography>
    <PasswordField  sx={{borderRadius: '8px'}}
      name="password" onChange={(e) => setNewPassword(e.target.value)}
      // disabled={disabled}
      value={newPassword}
    />
    <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
      {/* <Button sx={{borderRadius: '8px'}} onClick={() => setDisabled(false)}>Edit</Button> */}
      <Button sx={{borderRadius: '8px'}} variant="contained" onClick={() => handleSave()}>Save</Button>
      <Button sx={{borderRadius: '8px'}} onClick={()=> handleRemoveFile(fileName)} ><Delete /></Button>
    </Box>
  </Box>)

}

export default EditAnalysisFilePassword;