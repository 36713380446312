import { Box, CircularProgress, Typography } from "@mui/material";

const FullScreenLoader = ({ text }:{ text?: string }) => {
  return (
    <Box sx={{ position: 'fixed', display: 'flex',height: '100vh', justifyContent: 'center', alignItems: 'center', width: '100%', zIndex: 999, backgroundColor: 'rgba(0, 0, 0, 0.1)'}}>
      <CircularProgress color="primary" size={50} />
      {text && <Typography sx={{ ml: 2 }}>{text}</Typography>}
    </Box>
  );
}

export default FullScreenLoader;