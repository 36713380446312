import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface settingsState {
  callbackUrl: string;
  kongCustomerId: string;
  precisaPassword: string;
  precisaUsername: string;
  role: string;
  username: string;
}

const initialState: settingsState = {
  callbackUrl: "",
  kongCustomerId: "",
  precisaPassword: "",
  precisaUsername: "",
  role: "",
  username: "",
}
const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<settingsState>) => {
      state.callbackUrl = action.payload.callbackUrl;
      state.kongCustomerId= action.payload.kongCustomerId;
      state.precisaPassword=action.payload.precisaPassword;
      state.precisaUsername=action.payload.precisaUsername;
      state.username=action.payload.username;
      state.role=action.payload.role;
    },
    updateCallbackUrl:(state, action:PayloadAction<string>) => {
      state.callbackUrl = action.payload;
    },
    updatePrecisaCredentials: (state,action:PayloadAction<{precisaUsername:string,precisaPassword:string}>)=>{
      state.precisaUsername= action.payload.precisaUsername;
      state.precisaPassword = action.payload.precisaPassword;
    }
  },
});

export const {setSettings,updateCallbackUrl,updatePrecisaCredentials } = settingsSlice.actions;
export default settingsSlice.reducer;
