import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalStyles, ThemeProvider } from '@mui/material';
import Theme from './Theme';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from './context/SnackbarProvider';
import FullScreenLoader from './components/loaders/FullScreenLoader';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider theme={Theme}>
    <GlobalStyles
      styles={{
        '@keyframes mui-auto-fill': { from: { display: 'block' } },
        '@keyframes mui-auto-fill-cancel': { from: { display: 'block' } },
      }}
    />
    <Provider store={store}>
      <PersistGate loading={<FullScreenLoader />} persistor={persistor}>
        <SnackbarProvider>
        <App />
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
