import axios, {  AxiosError, AxiosResponse } from "axios";
import { AppDispatch, store } from "../redux/store";
import { clearToken } from "../redux/slices/userSlice";
import { notify } from "../context/notificationService";
import { AllTransactionsData, Irregularities, TransactionsTotalSummary, UserDetails } from "../redux/types";

const apiBase = "/api/bsa-backend";
const persistUrl = "https://preproduction-persist.signzy.tech/api/files/upload";
let token = "";
store.subscribe(() => {
   token = store.getState().user.token;
});
type ApiError = {
  reason: string;
  status: number;
  message: string;
  type: string;
  statusCode: number;
  name: string;
};

type ErrorResponse = {
  error: ApiError;
};
const tokenExpiredMsg = ["invalid or expired token","missing authorization header"];
const checkTokenError = (error:AxiosError<ErrorResponse>)=>{
  return (error.response?.data?.error?.status === 401 && tokenExpiredMsg.includes(error.response?.data?.error?.message))
}

const tokenExpiredHandler = () =>{
  notify("session expired please login again","error");
  (store.dispatch as AppDispatch)(clearToken());
}

const handleError = (error: unknown) => {
  console.error('API request failed:', error);
  if (error instanceof AxiosError) {
    if (checkTokenError(error)) {
      tokenExpiredHandler();
    }else{
      notify(error.response?.data?.error?.message || "something went wrong","error");
    }
  }else{
    console.log("error",error);
    notify("something went wrong","error");
  }
};

const loginApi = async (
  username: string,
  password: string,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post<AxiosResponse>(`${apiBase}/login`, {
      username,
      password,
    });
    return response;
  } catch (error) {
    console.error("Error during login:", error);
    handleError(error);
    throw error;
  }
};


interface UserResponse {
  callbackUrl: string;
  kongCustomerId: string;
  precisaPassword: string;
  precisaUsername: string;
  role: string;
  username: string;
}
const getUserApi = async (): Promise<UserResponse> => {
  try {
    const response = await axios.get<UserResponse>(`${apiBase}/get-user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
}

interface UploadResponse {
  file: {
    id: number;
    filetype: string;
    size: number;
    directURL: string;
    protected: boolean;
  };
}

/**
 * Uploads a file to the server and returns the response data.
 * @param file - The file to be uploaded.
 * @param ttl - Time-to-live duration for the file (e.g., "1 day").
 * @param isPublic - Whether the file should be public or not.
 * @returns The uploaded file's information.
 */
const uploadFile = async (file: File, ttl: string, isPublic: boolean): Promise<UploadResponse> => {
  const formData = new FormData();

  formData.append('ttl', ttl);
  formData.append('isPublic', String(isPublic));
  formData.append('file', file);

  try {
    const response = await axios.post<UploadResponse>(
      persistUrl,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Upload failed:', error);
    notify(`File upload failed: ${error}`,"error");
    throw error;
  }
};

interface Report {
  reportId: string;
  reportName: string;
  reportStatus: string;
  reportStatusDescription: string;
  reportCamStatus: string;
  reportType: string;
  lastUpdatedOn: string;
  createdOn: string;
}

interface Account {
  accountId: string;
  accountNumber: string;
  ifscCode: string;
  accountType: string;
  accountTypeName: string;
  bankId: string;
  bankName: string;
  status: string;
  statusDescription: string;
  camStatus: string;
  startDate: string;
  endDate: string;
  currency: string;
  currencyFormat: string;
  accountReferenceNumber: string;
  lastModifiedDate: string;
}

interface FileDetails {
  accountId: number;
  accountReferenceNumber: string;
  success: Array<{
    fileId: number;
    status: string;
    originalName: string;
    documentType: string;
    password: string;
  }>;
  failed: any[];
}

interface ReportAccount {
  account: Account;
  fileDetails: FileDetails;
}

interface AnalyzeStatementResponse {
  report: Report;
  reportAccounts: ReportAccount[];
  reportGst: any[];
  reportCreditBureaus: any[];
}

const analyzeStatements = async (
  files: { fileUrl: string; password: string }[]
): Promise<AnalyzeStatementResponse> => {
  try {
    const response = await axios.post<AnalyzeStatementResponse>(
      `${apiBase}/initiate-analysis`,
      { files },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Failed to initiate analysis:', error);
    handleError(error);
    throw error
  }
};

interface DownloadBSAReportParams {
  reportId: string;
  fileType: 'json' | 'excel' | 'json and excel' | 'excel and json';
}
interface DownloadBSAReportResult {
  excel?: string;
  json?: string;
}
interface DownloadBSAReportResponse{
  result?: DownloadBSAReportResult;
  status?: string;
  message?: string;
}
const downLoadBSAReport = async({reportId,fileType}:DownloadBSAReportParams): Promise<DownloadBSAReportResponse> =>{
  try {
    const response = await axios.post<DownloadBSAReportResponse>(
      `${apiBase}/download-report`,
      {
        reportId,
        fileType,
      },
      {
        headers:{
          'Content-Type':'applicaton/json',
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return response.data
  } catch (error) {
    handleError(error);
    throw error;
  }
}

const getStatusAnalytics = async (startDate: string, endDate: string): Promise<AxiosResponse> => {
  try {

    const response = await axios.post<AxiosResponse>(
      `${apiBase}/report-status-analytics`, {
        startDate,
        endDate
      },{
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      })
      return response;

  } catch (error) {
    handleError(error);
    throw error;
  }

}

interface BSAReportsParams {
  page?: number;
  limit?: number;
  startDate?: string;
  endDate?: string;
  status?: string[];
  sortFilter?: { [key: string]: number };
}

const getBSAReports = async ({
  page,
  limit,
  startDate,
  endDate,
  status,
  sortFilter,
}: BSAReportsParams): Promise<AxiosResponse> => {
  try {
    const requestBody: Record<string, any> = {};
    const defaultStartDate = new Date();
    defaultStartDate.setHours(0, 0, 0, 0);
    defaultStartDate.setDate( defaultStartDate.getDate()-30);
    requestBody.page = page || 1;
    requestBody.limit = limit || 20;
    requestBody.startDate = startDate || defaultStartDate.toISOString();
    requestBody.endDate = endDate || new Date().toISOString();
    if (status) requestBody.status = status;
    if (sortFilter) requestBody.sortFilter = sortFilter;

    const response = await axios.post<AxiosResponse>(
      `${apiBase}/get-bsa-reports`,
      requestBody,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

interface UpdateCustomerParams {
  precisaUsername?: string;
  precisaPassword?: string;
  callbackUrl?: string;
}

interface UpdateCustomerResponse {
  kongCustomerId: string;
  status: string;
  updatedFields: UpdateCustomerParams
}

const updateCustomerDetails = async (requestBody: UpdateCustomerParams): Promise<UpdateCustomerResponse> => {
  try {
    const response = await axios.post<UpdateCustomerResponse>(
      `${apiBase}/modify-customer`,
      requestBody,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

interface GetAllTransactionsParams {
  reportId: string;
  categories?: string[];
  fromDate?: string;
  toDate?: string;
  limit: number;
  page: number;
  sort?: 1 | -1;
}

const getAllTransactions = async(requestBody:GetAllTransactionsParams):Promise<AllTransactionsData>=>{
  try {
    const response = await axios.post<AllTransactionsData>(
      `${apiBase}/filter-transactions`,
      requestBody,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}
interface GetTransactionsFilterResponse {
  reportId:string;
  categories: string[];
  counterparties: string[];
  tags: string[];
}
const getTransactionsFilters = async(reportId:string):Promise<GetTransactionsFilterResponse>=>{
  try {
    const response = await axios.post<GetTransactionsFilterResponse>(
      `${apiBase}/list-entities`,
      {
        reportId
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}


const getTransactionsTotalSummary = async(reportId:string):Promise<TransactionsTotalSummary>=>{
  try {
    const response = await axios.post<TransactionsTotalSummary>(
      `${apiBase}/report-summary`,
      {
        reportId
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

interface MailSentResponse{
  username:string;
  message:string;
}
const addUser = async(username:string):Promise<MailSentResponse>=>{
  try {
    const response = await axios.post<MailSentResponse>(
      `${apiBase}/add-user`,
      {
        username,
      },
      {
        headers:{
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

const resendUserInvite = async(username:string):Promise<MailSentResponse>=>{
  try {
    const response = await axios.post<MailSentResponse>(
      `${apiBase}/resend-user-verification`,
      {
        username,
      },
      {
        headers:{
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

const sendResetPasswordLink = async(username:string):Promise<MailSentResponse>=>{
  try {
    const response = await axios.post<MailSentResponse>(
      `${apiBase}/forgot-password`,
      {
        username,
      },
      {
        headers:{
          'Content-Type': 'application/json',
        }
      }
    )
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

interface RemoveUserResponse{
  message: string;
	kongCustomerId: string;
	deleteCount:string;
}
const removeUser = async(username:string):Promise<RemoveUserResponse>=>{
  try {
    const response = await axios.post<RemoveUserResponse>(
      `${apiBase}/remove-user`,
      {
        username,
      },
      {
        headers:{
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      }
    )
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}


interface UpdatePasswordResponse{
  message:string;
}
const updateUserPassword = async(token:string,password:string):Promise<UpdatePasswordResponse> =>{
  try {
    const response = await axios.post<UpdatePasswordResponse>(
      `${apiBase}/update-password`,
      {
        token,
        password
      },
      {
        headers:{
          'Content-Type': 'application/json',
        }
      }
    )
    return response.data;
  } catch (error) {
    throw error;
  }
}

const getUsersList = async():Promise<UserDetails[]> =>{
  try {
    const response = await axios.get<UserDetails[]>(
      `${apiBase}/get-users-list`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

// Fraud Indicators
const getFraudIndicators = async(reportId: string):Promise<Irregularities>=>{
  try {
    const response = await axios.post<Irregularities>(
      `${apiBase}/get-irregularities-data`,{
        reportId,
      },
      {headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}



interface VerifiedUrlResponse {
  expired: boolean;
  tokenId: string;
  username:string;
}
const verifyUpdatePasswordUrl = async(verificationToken:string):Promise<VerifiedUrlResponse> =>{
  try {
    const response = await axios.get<VerifiedUrlResponse>(
      `${apiBase}/validate-update-password-url`,{
        headers: {
          Authorization: verificationToken,
        },
      }
    )
    return response.data;
  } catch (error) {
    throw error;
  }
}

export {
  loginApi,
  addUser,
  removeUser,
  resendUserInvite,
  sendResetPasswordLink,
  updateUserPassword,
  getUserApi,
  getUsersList,
  uploadFile,
  analyzeStatements,
  getStatusAnalytics,
  getBSAReports,
  downLoadBSAReport,
  updateCustomerDetails,
  getAllTransactions,
  getTransactionsFilters,
  getTransactionsTotalSummary,
  getFraudIndicators,
  verifyUpdatePasswordUrl,
};
