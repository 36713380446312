import analysisIcon from "../../assets/images/allanalysis.svg";
import dashboardIcon from "../../assets/images/dashboard.svg";
import settingsIcon from "../../assets/images/settings.svg";
import { store } from "../../redux/store";
let role="user"
store.subscribe(() => {
  role = store.getState().settings.role;
});

// import { useSelector } from "react-redux";
// import { rbacSelectors } from "@/redux/rbac-slice";
// import workflowIcon from "@/assets/icons/workflow_icon.svg";

export type SidebarProps = {
    isOpen: boolean;
    handleCollapseToggle: () => void;
};

export const SidebarStyles = (isDrawerCollapsed: boolean) => {
    return {
        "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: !isDrawerCollapsed ? "100px" : "300px",
            backgroundColor: "#37366F",
            display: "flex",
            transition: "all 0.3s ease",
            scrollbarWidth: !isDrawerCollapsed ? "none" : "auto",
        },
        "& .MuiDrawer-root": {
            overflowY: "scroll",
            backgroundColor: "#37366F",
        },
    };
};
export interface NavChild {
    title: string;
    path: string;
    icon?: any;
    children?: NavChild[];
    hide?: boolean;
}

export interface NavParent {
    title: string;
    children: NavChild[];
    hide?: boolean;
}

export interface miniNavProps {
    navData: NavParent[];
    handleCollapseToggle: () => void;
    indexClose?: any;
    setIndexClose?: any;
    isOpen: boolean;
}

export const useNavdata = () => {
  const navLinks: NavParent[] = [
    {
      title: "OVERVIEW",
      children: [
        {
          title: "Dashboard",
          path: "dashboard",
          icon: (
              <img src={dashboardIcon} style={{ maxWidth: "20px" }} alt="" />
          ),
        },
        {
          title: "All Analysis",
          path: "all-analysis",
          icon: (
              <img src={analysisIcon} style={{ maxWidth: "20px" }} alt="" />
          )
        },
        {
          title: "Settings",
          path: "settings",
          hide: role !== "admin" ? true : false,
          icon: (
              <img src={settingsIcon} style={{ maxWidth: "20px" }} alt="" />
          )
        }
      ],
    },
  ];
    return navLinks;
};
