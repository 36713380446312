import { Box, Button, CircularProgress, Typography } from "@mui/material";
import DayFilterButton from "../components/buttons/DayFilterButton";
import AnalyticsCard from "../components/cards/AnalyticsCard";
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ReportListCard from "../components/cards/ReportListCard";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getBSAReports, getStatusAnalytics } from "../api/apiCalls";
import { RootState } from "../redux/rootReducer";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setRecentAnalysisReportsList } from "../redux/slices/allAnalysisSlice";

type StatusKey = 'total' | 'analysed' | 'inProgress' | 'onHold';

const Dashboard = () => {
  const dayFilter = useSelector((state: RootState) => state.dashboard.currentDayFilter);
  const recentReports = useSelector((state:RootState)=>state.allAnalysis.recentReports);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dayFilterItems = ['Today', 'Yesterday', 'Last 7 days', 'Last 30 days', 'Last 90 days'];
  const data: Array<{ title: string; key: StatusKey; icon?: JSX.Element }> = [
    {
      title: 'Total',
      key: 'total',
      icon : <AnalyticsRoundedIcon fontSize="large"/>
    },
    {
      title: 'Analysed',
      key: 'analysed',
    },
    {
      title: 'In Progress',
      key: 'inProgress',
    },
    {
      title: 'On Hold',
      key: 'onHold',
    }
  ];

  const [statusData, setstatusData] = useState({
    "total":0,
    "analysed":0,
    "inProgress":0,
    "onHold":0
  });

  const [loading,setLoading] = useState(false);
  const [loadingReports,setLoadingReports] = useState(false);


  const fetchReportStatusAnalytics = async() => {
    setLoading(true);
    try {
      const endDate = new Date();
      let startDate = new Date();
      startDate.setHours(0, 0, 0, 0);

      switch (dayFilter) {
        case 'Yesterday':
          startDate.setDate(startDate.getDate() - 1);
          break;
        case 'Last 7 days':
          startDate.setDate(startDate.getDate() - 7);
          break;
        case 'Last 30 days':
          startDate.setDate(startDate.getDate() - 30);
          break;
        case 'Last 90 days':
          startDate.setDate(startDate.getDate() - 90);
          break;
        default:
          break;
      }
      const resp = await getStatusAnalytics(startDate.toISOString(), endDate.toISOString());
      const newStatusData = {total:0,analysed:0,inProgress:0,onHold:0};
      if(resp.data){
        newStatusData.total = resp.data.ALL;
        newStatusData.analysed = resp.data.POSTED + resp.data.ANALYSED + resp.data.CALLBACK_FAILED;
        newStatusData.inProgress = resp.data["IN PROGRESS"];
        newStatusData.onHold = resp.data["ANALYSIS ON HOLD"] + resp.data["FAILED"]
      }
      setstatusData(newStatusData);
    }catch (error) {
      console.log('error getting status analytics:', error);
    }finally{
      setLoading(false);
    }
  }

  const fetchRecentReports = async() => {
    try {
      setLoading(true);
      setLoadingReports(true);
      const resp = await getBSAReports({page: 1, limit: 20});
      if(resp.data){
        dispatch(setRecentAnalysisReportsList(resp.data.reports || []));
      }
    } catch (error) {
      console.log('error:', error);
    }finally{
      setLoadingReports(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchReportStatusAnalytics();
  },[dayFilter]);

  useEffect(() => {
    fetchRecentReports();
  },[]);

  return (
  <Box sx={{position:'relative'}}>{loading && <Box sx={{position:'absolute', height:'100%', display:'flex', zIndex:998, width:'100%', alignItems:'center', justifyContent:'center'}}>
    <CircularProgress color="primary" size={50} />
    </Box>}
    <Box sx={{padding: '30px'}}>
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography variant="h4" sx={{color: 'black', fontWeight: 700, fontSize:'24px'}}>
            Hi, Welcome Back 👋
          </Typography>
            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3 , gap: '10px'}}>
              {dayFilterItems.map((item, index) => {
                return (<DayFilterButton key={index} text={item}/>)
              })}
            </Box>
        </Box>
        <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '20px', mt: 3}}>
          {data.map((item, index) => {
            return (<AnalyticsCard key={index} title={item.title} value={statusData[item.key]} icon={item.icon}/>)
          })}
        </Box>
        <Box sx={{mt: 3}}>
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Typography variant="h4" sx={{color: 'black', fontWeight: 700, fontSize:'24px'}}>
              Recent Analysis
            </Typography>
              <Button variant="contained" size="small" sx={{paddingX: '20px', fontWeight: 700}} onClick={()=> navigate("/all-analysis")}>View all analysis<ArrowRightIcon /></Button>
          </Box>
          <Box sx={{mt: 3, borderRadius: '12px', border: '1px solid rgba(145, 158, 171, 0.2)', backgroundColor: 'white'}}>
            <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', p: 2, backgroundColor: 'customColors.gray-100', borderRadius: '12px 12px 0 0', borderBottom:'2px solid #EDEDF5'}}>
              <Typography>
                Report Id
              </Typography>
              <Typography>
                Created At
              </Typography>
              <Typography>
                Status
              </Typography>
              <Typography sx={{textAlign: 'right'}}>
                Download Analysis
              </Typography>
            </Box>
            <Box sx={{height:"50vh", overflowY:'scroll',scrollBehavior:'smooth'}}>
              {
                recentReports.length ?  recentReports?.map((report, index) => {
                  return <ReportListCard key={index} reportId={report.reportId} createdAt={report.createdAt} status={report.status}/>
                }): <Box sx={{height:'40vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
                  <Typography >{loadingReports ? "Loading recent reports..." :  "No recent analysis in this month !"}</Typography>
                </Box>
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Dashboard;