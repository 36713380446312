import React, { memo } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { miniNavProps } from "./NavConfig";
import { NavLink } from "react-router-dom";
import NavComponents from "./NavComponents";
// import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { DarkTooltip } from "../CustomTooltip";
import { RenderIf } from "../RenderIf";
import Logo from "../../assets/images/Logo.svg";
import { AddRounded } from "@mui/icons-material";
// import { useSelector } from "react-redux";
// import { rbacSelectors } from "@/redux/rbac-slice";
// import { USER_PERMISSIONS } from "@/utils/constants/userPermissions";

const MainNavbar: React.FC<miniNavProps> = ({
    navData,
    handleCollapseToggle,
    isOpen,
}) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: 'primary.dark',
                minHeight: "100vh",
                // maxWidth: "300px",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    mb: 3,
                }}
            >
                <NavLink to={"/"} style={{ marginLeft: "10px" }}>
                    <IconButton>
                        <img
                            src={Logo}
                            style={{ width: "40px" }}
                            alt={"Signzy"}
                        />
                    </IconButton>
                </NavLink>
                <DarkTooltip title={"Close Sidebar"} placement="right">
                    <Box sx={{ mr:'-18px', mt: 1 }}>
                        <IconButton
                            onClick={() => handleCollapseToggle()}
                            sx={{
                                color: "#fff",
                                background: "#37366F",
                                border: "dashed 1px rgba(145, 158, 171, 0.2)",
                                "&:hover": {
                                    background: "#37366F",
                                },
                            }}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                    </Box>
                </DarkTooltip>
            </Box>
            <Box

                    sx={{
                        width: "90%",
                        paddingBottom: "10px",
                    }}
                >
                        <NavLink to={"/initiate-analysis"}>
                            <Button
                                sx={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    background: "#4F4D9E",
                                    gap: "10px",
                                }}
                            >
                                <AddRounded sx={{ color: "#fff", width: "20px", height: "20px"}} />
                               <Typography sx={{ color: "#fff", textTransform: "capitalize", fontSize: "15px"}}>Initiate Analysis</Typography>
                            </Button>
                        </NavLink>
                </Box>
            {navData.map((el, key) => {
                return (
                    <RenderIf condition={!el.hide} key={key}>
                        <Box
                            key={key}
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <NavComponents Items={el} isOpen={isOpen} />
                        </Box>
                    </RenderIf>
                );
            })}
        </Box>
    );
};

export default memo(MainNavbar);
