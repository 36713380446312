import { ArrowOutward, Clear, FilterList, SouthWest, SwapVert } from "@mui/icons-material";
import { Box, Button, Modal, Paper, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, Typography } from "@mui/material";
import {  useState } from "react";
import TransactionsFilter from "./TransactionsFilter";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { clearTransactionsFilter, openTransactionsFilter, setAppliedTransactionsFilter, setTransactionsActiveType, setTransactionsData, setTransactionsFilter } from "../../redux/slices/reportSlice";
import { getAllTransactions } from "../../api/apiCalls";
import { formatIndianCurrency } from "../../utils/utils";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
interface AllTransactionsProps{
  reportId:string;
}

type TransactionTypes = "CR" | "DB" | "";

interface fetchAllTransactionsDataProps{
  newPage?:number;
  newLimit?:number;
  categories?: string[];
  counterparty?:string[];
  tags?:string[];
  sort?:1 | -1;
  type?: "CR" | "DB" | "";
}

const rows = Array.from({ length: 15 });
const columns = Array.from({ length: 11 });
const transactionTypes:TransactionTypes[] = ["","DB","CR"];

const AllTransactions = ({reportId}:AllTransactionsProps) => {
  const transactionsReady = useSelector((state:RootState)=>state.report.transactionsReady);
  const page = useSelector((state:RootState)=>state.report.transactions.data.page);
  const totalTransactions = useSelector((state:RootState)=>state.report.transactions.data.totalTransactions);
  const summary = useSelector((state:RootState)=>state.report.transactions.summary);
  const transactions = useSelector((state:RootState)=>state.report.transactions.data.transactions);
  const filterOpened =useSelector((state:RootState)=>state.report.transactions.filterOpened);
  const appliedFilters = useSelector((state:RootState)=>state.report.transactions.appliedFilters);
  const activeType = useSelector((state:RootState)=>state.report.transactions.activeType);
  const dispatch = useDispatch();
  const [loadingTransactions,setLoadingTransactions] = useState(false);

  const fetchAllTransactionsData = async({newPage,newLimit,categories,counterparty,tags,sort,type}:fetchAllTransactionsDataProps)=>{
    try {
      const {count,...newFilters} = appliedFilters;
      if(categories){
        newFilters.categories = categories;
      }
      if(counterparty){
        newFilters.counterparty = counterparty;
      }
      if(tags){
        newFilters.tags = tags;
      }
      if(newLimit){
        newFilters.limit = newLimit;
      }
      if(type !== undefined){
        newFilters.type = type;
      }
      if(sort !== undefined){
        newFilters.sort = sort;
      }
      dispatch(setTransactionsFilter(newFilters));
      dispatch(setAppliedTransactionsFilter(newFilters));
      setLoadingTransactions(true)
      const data = await getAllTransactions({reportId,...newFilters, page: newPage || page});
      dispatch(setTransactionsData(data));
    } catch (error) {
      console.log("error",error);
    }finally{
      setLoadingTransactions(false);
    }
  }

  const handleTypeChange = (event: React.SyntheticEvent, newTab: number) => {
    dispatch(setTransactionsActiveType(newTab))
    fetchAllTransactionsData({newPage:1,type:transactionTypes[newTab]})
  };

  const handleSortTransactions = ()=>{
    fetchAllTransactionsData({newPage:1, sort: appliedFilters.sort === 1 ? -1 : 1})
  }
  const handleChangePage = (page:number)=>{
    fetchAllTransactionsData({newPage:page+1});
  }
  const handleChangeRowsPerPage = (limit:number)=>{
    fetchAllTransactionsData({newPage:1,newLimit:limit});
  }
  const handleClearAllFilter = ()=>{
    dispatch(clearTransactionsFilter());
    setLoadingTransactions(true);
    fetchAllTransactionsData({newPage:1,categories:[],tags:[],counterparty:[]});
  }
  return (
    <>
      <Modal
        open={filterOpened}
        onClose={()=> dispatch(openTransactionsFilter(false))}
      >
        <Box sx={{display:"flex"}}>
          <Box sx={{flexGrow:1}} onClick={()=>dispatch(openTransactionsFilter(false))}></Box>
          <TransactionsFilter/>
        </Box>
      </Modal>
      <Paper sx={{backgroundColor:"customColors.white", borderRadius:"12px",minHeight:"70vh"}}>
        <Box sx={{padding:"24px"}}>
          <Typography fontWeight={700} lineHeight="30px" fontSize={24} variant="h5" marginBottom={2}>
            All Transactions
          </Typography>
          <Box sx={{display:"grid",gap:"24px", gridTemplateColumns:"1fr 1fr 1fr"}}>
            <Paper sx={{padding:"20px",border:"1px solid #919EAB33", borderRadius:"12px" ,minWidth:"200px"}}>
              <Typography color="customColors.gray-200" fontWeight={600} fontSize={14}>
                Total Income
              </Typography>
              <Typography marginTop={1} variant="h4" fontSize={24} lineHeight="36px" fontWeight={700}>
                {!transactionsReady ? <Skeleton /> : <>₹{formatIndianCurrency(summary.totalIncome)}</>}
              </Typography>
            </Paper>
            <Paper sx={{padding:"20px",border:"1px solid #919EAB33", borderRadius:"12px" ,minWidth:"200px"}}>
              <Typography color="customColors.gray-200" fontWeight={600} fontSize={14}>
                Total Expenses
              </Typography>
              <Typography marginTop={1} variant="h4" fontSize={24} lineHeight="36px" fontWeight={700}>
                {!transactionsReady ? <Skeleton /> :  <>₹{formatIndianCurrency(summary.totalExpense)}</>}
              </Typography>
            </Paper>
            <Paper sx={{padding:"20px",border:"1px solid #919EAB33", borderRadius:"12px" ,minWidth:"200px"}}>
              <Typography color="customColors.gray-200" fontWeight={600} fontSize={14}>
                Current Balance
              </Typography>
              <Typography marginTop={1} variant="h4" fontSize={24} lineHeight="36px" fontWeight={700}>
                {!transactionsReady ? <Skeleton /> : <>₹{formatIndianCurrency(summary.currentBalance)}</>}
              </Typography>
            </Paper>
          </Box>
        </Box>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={activeType} onChange={handleTypeChange} aria-label="transactions" sx={{height:"60px"}}>
              <Tab label="All" {...a11yProps(0)} sx={{color:"customColors.gray-200", textTransform:"none", fontWeight:"700", padding:"8px"}}/>
              <Tab label="Sent" {...a11yProps(1)} sx={{color:"customColors.gray-200", textTransform:"none", fontWeight:"700",padding:"8px"}} icon={<ArrowOutward fontSize="medium" sx={{margin:0,padding:0}} />} iconPosition="start"/>
              <Tab label="Recieved" {...a11yProps(2)} sx={{color:"customColors.gray-200", textTransform:"none", fontWeight:"700",padding:"8px"}} icon={<SouthWest fontSize="medium" sx={{margin:0,padding:0}} />} iconPosition="start"/>
            </Tabs>
          </Box>
          <Box sx={{display:"flex", justifyContent:"flex-end",padding:"28px"}}>
            <Box>
              <Button startIcon={<FilterList />} variant="outlined" sx={{color:"customColors.gray-200", fontWeight: 700, fontSize:"14px", lineHeight:"24px"}} onClick={()=>dispatch(openTransactionsFilter(true))}>
              <Box>
                Filter
              </Box>
              <Box sx={{ml:"8px", height:"20px", width:"20px", borderRadius:"100%",backgroundColor:"primary.main",color:"white", fontSize:"12px",alignItems:"center", display:"flex", justifyContent:"center"}}>
                {appliedFilters.count}
              </Box>
              </Button>
              <Button startIcon={<SwapVert />} variant="outlined" sx={{color:"customColors.gray-200", fontWeight: 700, fontSize:"14px",ml:"10px", lineHeight:"24px"}} onClick={handleSortTransactions}>
                Sort
              </Button>
            </Box>
          </Box>
        </Box>
        {appliedFilters.count > 0 && <Box sx={{display:"flex",gap:"8px",mb:"12px", px:"28px"}}>
          <Typography color="customColors.gray-200" fontSize={14} whiteSpace="nowrap">Filtered by</Typography>
          <Box sx={{flexGrow:1,display:"flex", flexWrap:"wrap",gap:"8px"}}>
            {appliedFilters.categories.length > 0 && <Box sx={{ display:"flex",alignItems:"center",gap:"4px", backgroundColor:"customColors.purple-200", p:"4px", borderRadius:"8px"}}>
                <Typography sx={{color:"customColors.blue-700",fontWeight:600,fontSize:"12px"}}><span style={{fontWeight:400}}>Category:</span> {appliedFilters.categories.join(", ")}</Typography>
                <Clear sx={{cursor:"pointer",fontSize:"16px"}} onClick={()=>fetchAllTransactionsData({newPage:1,categories:[]})}/>
              </Box>
            }
            {appliedFilters.counterparty.length > 0 && <Box sx={{ display:"flex",alignItems:"center",gap:"4px", backgroundColor:"customColors.purple-200", p:"4px", borderRadius:"8px"}}>
                <Typography sx={{color:"customColors.blue-700",fontWeight:600,fontSize:"12px"}}><span style={{fontWeight:400}}>Counterparty:</span> {appliedFilters.counterparty.join(", ")}</Typography>
                <Clear sx={{cursor:"pointer",fontSize:"16px"}} onClick={()=>fetchAllTransactionsData({newPage:1,counterparty:[]})}/>
              </Box>
            }
            {appliedFilters.tags.length > 0 && <Box sx={{ display:"flex",alignItems:"center",gap:"4px", backgroundColor:"customColors.purple-200", p:"4px", borderRadius:"8px"}}>
                <Typography sx={{color:"customColors.blue-700",fontWeight:600,fontSize:"12px"}}><span style={{fontWeight:400}}>Tag:</span> {appliedFilters.tags.join(", ")}</Typography>
                <Clear sx={{cursor:"pointer",fontSize:"16px"}} onClick={()=>fetchAllTransactionsData({newPage:1,tags:[]})}/>
              </Box>
            }
          </Box>
          <Button sx={{height:"20px",fontSize:"12px", fontWeight:600,color:"customColors.blue-600", whiteSpace:"nowrap"}} onClick={handleClearAllFilter}>
            <Clear sx={{cursor:"pointer",fontSize:"16px"}}/>
            Clear all
          </Button>
        </Box>
        }
        <Box>
          <TableContainer sx={{height:"70vh", overflow:"scroll",borderTop:"1px solid", borderColor: 'divider'}}>
            <Table stickyHeader  sx={{width:"1800px"}}>
              <TableHead sx={{"& .MuiTableCell-head":{
                color:"customColors.gray-200",
                fontWeight:600,
                fontSize:"14px",
                lineHeight:"24px",
                whiteSpace:"nowrap",
                backgroundColor:"customColors.white"
              }}}>
                <TableRow>
                  <TableCell>
                    Date
                  </TableCell>
                  <TableCell>
                    Particulars
                  </TableCell>
                  {/* <TableCell>
                    Flags
                  </TableCell> */}
                  <TableCell>
                    Cheque/Ref. No.
                  </TableCell>
                  <TableCell>
                    Counterparty
                  </TableCell>
                  <TableCell>
                    Amount (₹)
                  </TableCell>
                  <TableCell>
                    Balance (₹)
                  </TableCell>
                  <TableCell>
                    Computed balance (₹)
                  </TableCell>
                  <TableCell>
                    Tags
                  </TableCell>
                  <TableCell>
                    Category
                  </TableCell>
                  <TableCell>
                    UPI app
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{backgroundColor:"customColors.white",'& .MuiTableRow-root:nth-of-type(even)':{
                backgroundColor:"#919EAB14"
              }}}>
                { loadingTransactions ? (
                  rows.map((row,index) => (
                    <TableRow key={index}>
                      {columns.map((column,columnIdx)=>(
                      <TableCell key={columnIdx}>
                        <Skeleton />
                      </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  transactions.length > 0  ?
                  transactions.map((transaction,index) => (
                    <TableRow key={index}>
                      <TableCell sx={{textWrap:"nowrap"}}>
                        {new Date(transaction.date).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric'
                        })}
                      </TableCell>
                      <TableCell>
                        {transaction.particular}
                      </TableCell>
                      {/* <TableCell>
                        {transaction.flag}
                      </TableCell> */}
                      <TableCell>
                        {transaction.cheque_number}
                      </TableCell>
                      <TableCell>
                        {transaction.counterparty}
                      </TableCell>
                      <TableCell sx={{color: transaction.type === "CR" ? "#009D7B":"#D76662",textWrap:"nowrap",textAlign:"right"}}>
                        {transaction.type === "CR" ? "+ ₹" : "- ₹"}
                        {formatIndianCurrency(transaction.amount)}
                      </TableCell>
                      <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                      ₹{formatIndianCurrency(transaction.balance)}
                      </TableCell>
                      <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                      ₹{formatIndianCurrency(transaction.computedBalance)}
                      </TableCell>
                      <TableCell>
                        <Box sx={{display:"flex",flexWrap:"wrap", gap:"8px"}}>
                        {transaction.tags?.map((tag,index)=>(
                          <Box sx={{borderRadius:"8px", padding:"4px", backgroundColor:"customColors.gray-50",fontSize:"13px",fontWeight:500,whiteSpace:"nowrap"}} key={index}>
                            {tag}
                          </Box>
                        ))}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{display:"flex",flexWrap:"wrap", gap:"8px",fontSize:"13px",fontWeight:500}}>
                          {transaction.categories.split(" / ").map((category,index)=>(
                            <Box sx={{borderRadius:"8px", padding:"4px", backgroundColor:"customColors.purple-200",color:"customColors.blue-700",whiteSpace:"nowrap"}} key={index}>
                            {category}
                            </Box>
                          ))}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {transaction.upiApp}
                      </TableCell>
                    </TableRow>
                  )) : <TableRow>
                  <TableCell colSpan={11} sx={{ border: "none" }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" , fontSize:"18px"}}>
                      No transactions to show!
                    </Box>
                  </TableCell>
                </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalTransactions}
            page={page-1}
            onPageChange={(e,page) => handleChangePage(page)}
            rowsPerPage={appliedFilters.limit}
            rowsPerPageOptions={[200,500,1000,1500]}
            onRowsPerPageChange={(e)=>handleChangeRowsPerPage(Number(e.target.value))}
          />
        </Box>
      </Paper>
    </>
  )
}

export default AllTransactions;