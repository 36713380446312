import { Button } from "@mui/material"
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { useDispatch } from "react-redux";
import { setCurrentDayFilter } from "../../redux/slices/dashboardSlice";

interface DayFilterButtonProps {
  text: string;
}
const DayFilterButton: React.FC<DayFilterButtonProps> = ({text}) => {
  const active = useSelector((state: RootState) => state.dashboard.currentDayFilter) === text;
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setCurrentDayFilter(text));
  }

  return (
    <Button variant="contained"
      sx={{
        backgroundColor: active ? 'primary' : 'rgb(245, 245, 245)',
        color: active ? 'white' : 'rgb(109, 110, 115)',
        fontWeight: 700,
        textTransform: 'none',
        borderRadius: '8px',
        boxShadow: 'rgba(145, 158, 171, 0.24) 0px 8px 16px 0px'
      }}
      size="small"
      onClick={handleClick}
      >
      {text}
    </Button>
  )
}

export default DayFilterButton;