import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


type TemplatePdfViewerProps = {
    sourceUrl: any;
    isModal: boolean;
};

const TemplatePdfViewer: React.FC<TemplatePdfViewerProps> = ({
    sourceUrl,
    isModal = false,
}) => {
    // const [pdfScale, setPdfScale] = useState(1);
    const pdfScale = 1;
    const [pdfPageCount, setPdfPageCount] = useState(1);
    // const [currentPage, setCurrentPage] = useState(1);
    const pageRef = useRef<HTMLElement>(null);


    // const handleScroll = () => {
    //     if (pageRef.current) {
    //         const { scrollTop } = pageRef.current;
    //         const pageHeight = pageRef.current.scrollHeight / pdfPageCount;
    //         const currentPage = Math.floor(scrollTop / pageHeight) + 1;
    //         setCurrentPage(currentPage);
    //     }
    // };

    const previewStyle = {
        // ...docPreviewModalStyle,
        height: "100%"
   };

    return (
        <React.Fragment>
            <Box sx={{ ...previewStyle,display:"flex", justifyContent:"center",alignItems:"center" }}>
                <Box
                    ref={pageRef ?? null}
                    // onScroll={handleScroll}
                    zIndex={0}
                    sx={{height:"100%"}}
                >
                    <Document
                        file={sourceUrl}
                        noData={<Typography>No PDF data available</Typography>}
                        onLoadSuccess={({ numPages }) =>
                            setPdfPageCount(numPages)
                        }
                        onError={console.error}
                        loading={<CircularProgress sx={{mt:"30vh"}} />}
                        rotate={0}
                    >
                        {Array.from(
                            new Array(pdfPageCount || 0),
                            (el, index) => (
                                <Box key={index} sx={{width:"100%", minHeight:"80vh"}}>
                                    {/* <Typography
                                        mt={1}
                                        mb={1}
                                        textAlign={"center"}
                                    >
                                        Page {index + 1}
                                    </Typography> */}
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        renderTextLayer={false}
                                        renderAnnotationLayer={false}
                                        scale={pdfScale}
                                        width={window.screen.width*0.57}
                                        loading={<CircularProgress sx={{mt:"30vh"}} />}
                                    />
                                </Box>
                            )
                        )}
                    </Document>
                </Box>
                {/* <Box
                    sx={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#85878C",
                        width: "100%",
                        position: "relative",
                        padding: "10px",
                    }}
                >
                    <RenderIf condition={isModal}>
                        <Box
                            sx={{
                                flex: "0 1 auto",
                                ml: 4,
                                width: 50,
                            }}
                        >
                            <Typography color={"#6D6E73"} fontWeight={700}>
                                {currentPage} / {pdfPageCount}
                            </Typography>
                        </Box>
                    </RenderIf>
                    <Box
                        sx={{
                            flex: "1 1 auto",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 2,
                            mr: isModal ? "70px" : 0,
                        }}
                    >
                        <LuZoomIn
                            onClick={() =>
                                setPdfScale((prev) =>
                                    prev + 0.1 < PREVIEW_PDF_SIZE.max
                                        ? prev + 0.1
                                        : PREVIEW_PDF_SIZE.max
                                )
                            }
                            style={{ cursor: "pointer" }}
                        />
                        <Typography>
                            {pdfScale
                                ? `${parseInt(String(pdfScale * 100))}%`
                                : "100%"}
                        </Typography>
                        <LuZoomOut
                            onClick={() =>
                                setPdfScale((prev) =>
                                    prev - 0.1 > PREVIEW_PDF_SIZE.min
                                        ? prev - 0.1
                                        : PREVIEW_PDF_SIZE.min
                                )
                            }
                            style={{ cursor: "pointer" }}
                        />
                    </Box>
                </Box> */}
            </Box>
        </React.Fragment>
    );
};

export default TemplatePdfViewer;
