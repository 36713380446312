import { Box, Button, CircularProgress, Typography } from "@mui/material";
import FileInput from "../components/dropbox/FileInput";
import PDFViewerModal from "../components/modal/PDFViewer";
import CustomModal from "../components/modal/Modal";
import EditAnalysisFilePassword from "../components/EditAnalysisFilePassword";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import { useDispatch } from "react-redux";
import { clearAnalysisFilesArray, closePreview, setAnalysisFilesArray, setUploadingFiles, setUploadingText } from "../redux/slices/initiateAnalysisSlice";
import { analyzeStatements } from "../api/apiCalls";
import { useSnackbar } from "../context/SnackbarProvider";

const InitiateAnalysis = () => {
  const filesData = useSelector((state: RootState) => state.initiateAnalysis.filesArray);
  const showModal = useSelector((state: RootState) => state.initiateAnalysis.showModal);
  const previewUrl = useSelector((state: RootState) => state.initiateAnalysis.previewUrl);
  const uploadingFiles = useSelector((state: RootState) => state.initiateAnalysis.uploadingFiles);
  const uploadingText = useSelector((state: RootState) => state.initiateAnalysis.uploadingText);
  const dispatch = useDispatch();
  const { showNotification } = useSnackbar();

  const handleAnalysis = async() => {
    console.log('analysis files:', filesData);
    dispatch(setUploadingText('Initiating Analysis...'))
    dispatch(setUploadingFiles(true));
    const files = filesData.map(file => {
      return {fileUrl: file.fileUrl, password: file.password}
    })
    try {
      const response = await analyzeStatements(files);
      console.log('Analysis initiated successfully:', response);
      showNotification(`Analysis Initiated for ${response?.report?.reportId}`,'info');
      dispatch(setAnalysisFilesArray([]))
    } catch (error) {
      console.log('error initiating analysis:', error);
    }finally{
      dispatch(setUploadingFiles(false));
      dispatch(setUploadingText(''))
    }
  }



  return (
    <Box sx={{position:'relative'}}>{uploadingFiles && <Box sx={{position:'absolute', height:'100%', maxHeight:'100vh', display:'flex', zIndex:998, width:'100%', alignItems:'center', justifyContent:'center'}}>
    <CircularProgress color="primary" size={50} />
    <Typography color="primary.main" fontSize={18} fontWeight={500} >{uploadingText}</Typography>
    </Box>}
    <Box sx={{padding: '20px'}}>
      <CustomModal open={showModal} onClose={() => dispatch(closePreview())}><PDFViewerModal isModal={true} sourceUrl={previewUrl}  />
      </CustomModal>
      <Typography variant="h4" sx={{color: 'black', fontWeight: 700, fontSize:'24px'}}>
        Initiate Analysis
      </Typography>
      <Box sx={{border:'1px solid rgba(145, 158, 171, 0.2)', borderRadius: '8px', padding: '40px', my: '32px'}}>
       <FileInput />
        {filesData.length > 0 && (
          <Box mt={2} sx={{display:'grid',gap:'10px',}}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <Typography variant="subtitle1">Selected Files:</Typography>
              <Box>
                <Button variant="outlined" sx={{borderRadius: '8px', mr: '10px'}} onClick={() => dispatch(clearAnalysisFilesArray())}>Remove All</Button>
                <Button variant="contained" sx={{borderRadius: '8px'}} onClick={() => handleAnalysis()} >Start Analysis</Button>
              </Box>
            </Box>
            <Box sx={{p: 2, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', alignItems: 'center'}}>
              <Typography>Name</Typography>
              <Typography>File Size</Typography>
              <Typography>Enter Password</Typography>
            </Box>
            <Box sx={{display:'grid',gap:'10px', maxHeight:'25vh', overflow:'auto', scrollBehavior:'smooth'}}>
              {filesData.map(({fileUrl, fileName, password,size}, index) => {
                return(
                  <EditAnalysisFilePassword  key={index} file={fileUrl} fileName={fileName} password={password} size={size} />
              )})}
            </Box>
          </Box>
      )}
      </Box>
    </Box>
    </Box>
  )
}

export default InitiateAnalysis;