import { Box, Tab, Tabs, Typography } from "@mui/material"
import { useState } from "react";
import AnalysisSettings from "../components/settings/AnalysisSettings";
import CustomTabPanel from "../components/CustomTabPanel";
import UserSettings from "../components/settings/UserSettings";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SettingsPage = ()=>{
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box sx={{padding: '30px'}}>
      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography variant="h4" sx={{color: 'black', fontWeight: 700, fontSize:'24px'}}>
          Settings
        </Typography>
      </Box>
      <Box sx={{ width: '100%',mt:"12px" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="settings">
          <Tab label="Analysis Settings" {...a11yProps(0)} sx={{color:"customColors.gray-200", textTransform:"none", fontWeight:"700"}}/>
          <Tab label="User settings" {...a11yProps(1)} sx={{color:"customColors.gray-200", textTransform:"none", fontWeight:"700"}}/>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <AnalysisSettings />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <UserSettings />
      </CustomTabPanel>
    </Box>
    </Box>
  )
}

export default SettingsPage;