import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface userState {
  username: string;
  token: string;
  expiryTime:number;
  password: string;
  rememberMe: boolean;
}

const initialState: userState = {
  username: "",
  token: "",
  expiryTime: Date.now(),
  password: "",
  rememberMe: true,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<{username:string,token:string,password:string,expiryTime:number}>) {
      state.username = action.payload.username;
      state.token = action.payload.token;
      state.password = action.payload.password;
      state.expiryTime = action.payload.expiryTime;
    },
    setPassWord(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
    setUserName(state, action: PayloadAction<string>) {
      state.username = action.payload;
    },
    setRememberUser(state, action: PayloadAction<boolean>) {
      state.rememberMe = action.payload;
    },
    clearToken(state) {
      state.token = "";
      state.expiryTime = Date.now();
      if(!state.rememberMe){
        state.password = "";
        state.username = "";
        state.rememberMe = true
      }
    },
  },
});

export const { setUser, setUserName, setPassWord, clearToken, setRememberUser } = userSlice.actions;
export default userSlice.reducer;
