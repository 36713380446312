import React, { memo } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { DarkTooltip } from "../CustomTooltip";
import { miniNavProps } from "./NavConfig";
import { Link, NavLink, useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { NavLinksWithoutChildren, NavLinkWithChildren } from "./NavLinks";
// import { rbacSelectors } from "@/redux/rbac-slice";
import { RenderIf } from "../RenderIf";
import Logo from "../../assets/images/Logo.svg";
import { AddRounded } from "@mui/icons-material";
// import { USER_PERMISSIONS } from "@/utils/constants/userPermissions";

const MiniNavbar: React.FC<miniNavProps> = ({
  navData,
  handleCollapseToggle,
  isOpen,
}) => {
  // const groupPermissions = useSelector(rbacSelectors.getGroupPermissions);
  const { pathname } = useLocation();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // maxWidth: "100px",
        backgroundColor: 'primary.dark',
        minHeight: "100vh",
        position: "relative"
      }}
    >
      <Box>
        <Link to={"/"}>
          <IconButton>
            <img src={Logo} style={{ width: "30px" }} alt="Signzy"/>
          </IconButton>
        </Link>
      </Box>
      <Box sx={{position:'absolute',right:-18, top:10}}>
        <DarkTooltip
            arrow
            title={"Open Sidebar"}
            placement="right"
            slotProps={{
                popper: {
                    modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, 0],
                          },
                        },
                    ],
                },
            }}
          >
          <IconButton
            onClick={() => handleCollapseToggle()}
            sx={{
                color: "#fff",
                background: "#37366F",
                border: "dashed 1px rgba(145, 158, 171, 0.2)",
                "&:hover": {
                    background: "#37366F",
                },
            }}
          >
          <ChevronRightIcon fontSize={"small"} />
        </IconButton>
          </DarkTooltip>
      </Box>
      <Box
        sx={{
            width: "100%",
            mt: 1,
            mb: 1,
            display: "flex",
            justifyContent: "center",
        }}
      >
        <DarkTooltip
            arrow
            title={"Initiate Analysis"}
            placement="right"
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 15],
                            },
                        },
                    ],
                },
            }}
        >
          <NavLink to={"/initiate-analysis"}>
            <Button
                sx={{
                    width: "70%",
                    padding: "5px",
                    borderRadius: "10px",
                    background: "#4F4D9E",
                }}
            >
              <AddRounded sx={{ color: "#fff"}}/>
            </Button>
          </NavLink>
        </DarkTooltip>
      </Box>
      <Box
          sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
          }}
      >
          {navData.map((el, key) => {
              return (
                  <RenderIf key={key} condition={!el.hide}>
                      {el.children.map((links, key) => (
                          <Box
                              sx={{
                                  background: pathname?.includes(
                                      links.path
                                  )
                                      ? "rgba(145, 158, 171, 0.32)"
                                      : "none",
                                  width: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "100%",
                              }}
                              key={key}
                          >
                              <NavLink to={links.path}>
                                  {links.children ? (
                                      <NavLinkWithChildren
                                          isOpen={isOpen}
                                          links={links}
                                          pathname={pathname}
                                      />
                                  ) : (
                                      <NavLinksWithoutChildren
                                          isOpen={isOpen}
                                          links={links}
                                          isSelected={pathname?.includes(
                                              links.path
                                          )}
                                      />
                                  )}
                              </NavLink>
                          </Box>
                      ))}
                  </RenderIf>
              );
          })}
      </Box>
    </Box>
  );
};

export default memo(MiniNavbar);
