import React from 'react';
import { Modal, Box, Button } from '@mui/material';

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  title?: string; // Optional title for the modal
  children: React.ReactNode; // This will accept any React component
}

const CustomModal: React.FC<CustomModalProps> = ({ open, onClose, title, children }) => {
  return (
    <Modal open={open} onClose={onClose} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Box sx={{ position: 'absolute', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            height: '80vh',
            width:'60vw',
            p: 4,
            borderRadius: 2,
            overflow: 'auto',
          }}
        >
          {title && <h2>{title}</h2>}
          {children}
        </Box>
        <Box sx={{display:'flex',justifyContent: 'flex-end'}}>
        <Button variant="contained" color="primary" onClick={onClose} sx={{ mt: 2}}>
          Close
        </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
