import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Box, Button, Popover } from "@mui/material";
import { RootState } from "../redux/rootReducer";
import Sidebar from "../components/sidebar/Sidebar";
import { useEffect, useState } from "react";
import { LogoutRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { clearToken } from "../redux/slices/userSlice";
import { useSnackbar } from "../context/SnackbarProvider";
import { getUserApi } from "../api/apiCalls";
import { setSettings } from "../redux/slices/settingsSlice";

const notHeaderPages = ["/all-analysis/"]

const ProtectedRoutes = () => {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {showNotification} = useSnackbar();

  const [isOpen,setIsOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [showHeader,setShowHeader] = useState(true);

  const fetchUserDetails = async()=>{
    try {
      const userdetails = await getUserApi();
      dispatch(setSettings(userdetails));
    } catch (error) {
      console.log("error getting complete user details:", error);
    }
  }

  useEffect(()=>{
    if(user.token){
      fetchUserDetails();
    }
  },[user.token])

  useEffect(()=>{
    if(user.token && (user.expiryTime < Date.now())){
      showNotification("session expired please login again!","error");
      dispatch(clearToken());
    }
    if(notHeaderPages.some((path) => pathname.startsWith(path))){
      setShowHeader(false);
    }else{
      setShowHeader(true);
    }
  },[pathname])

  if(!user.token){
    return <Navigate to="/login" replace />
  }
  const handleCollapseToggle = () => setIsOpen(!isOpen);
  const handleLogout=() => {
    dispatch(clearToken());
  }
  return <Box sx={{ display: "flex" }}>
    <Sidebar isOpen={isOpen} handleCollapseToggle={handleCollapseToggle}/>
    <Box sx={{ width: "100%", padding: showHeader ? "20px 20px": "0px", backgroundColor: showHeader ? "rgb(237, 237, 245)" : "#FFFFFF", maxHeight: "100vh", overflowY: "auto" }}>
      <Box sx={{ display: showHeader ? "flex" : "none", justifyContent: "flex-end" }}>
        <Button sx={{ color: "white", backgroundColor: "primary.main", borderRadius: "100%", height: "48px", minWidth: "48px",fontWeight: 700, fontSize: "14px", justifyContent: "center", alignItems: "center", display: "flex", boxShadow: "rgba(145, 158, 171, 0.24) 0px 1px 2px 0px"}} onClick={(event) => setAnchorEl(event.currentTarget)}>
            {user.username.slice(0, 2).toUpperCase()}
        </Button>
        <Popover
          id="simple-popover"
          open={open}
          anchorEl={anchorEl}
          onClose={()=>setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{mt: 1, borderRadius: '8px'}}
        >
          <Box sx={{ padding: "4px", display: "flex", flexDirection: "column", gap: "6px" }}>
            <Button sx={{color: 'error.main', minWidth: '200px', justifyContent: 'flex-start'}} onClick={handleLogout} ><LogoutRounded sx={{color: 'error.main',marginRight: '4px'}} /> Logout</Button>
          </Box>
        </Popover>
      </Box>
    <Outlet />
    </Box>
  </Box>
}

export default ProtectedRoutes;