import { IconButton, Box, Typography, Button, Collapse } from "@mui/material";
import { DarkTooltip, LinkTooltip } from "../CustomTooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CircleIcon from "@mui/icons-material/Circle";
import {  Key, useState } from "react";
import { NavLink } from "react-router-dom";
import { RenderIf } from "../RenderIf";
import { NavChild } from "./NavConfig";

interface NavLinkProps {
  isOpen: boolean;
  links: NavChild;
  isSelected: boolean;
}
interface NavLinkWithChildrenProps {
  isOpen: boolean;
  links: NavChild;
  pathname: string;
}
export const NavLinksWithoutChildren: React.FC<NavLinkProps> = ({ isOpen, links, isSelected }) => {
    if (isOpen) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    mt: 1,
                    mb: 1,
                }}
            >
                <Button
                    sx={{
                        width: "90%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        background: isSelected ? "rgba(145, 158, 171, 0.12)" : "",
                        border: isSelected ? "1px solid rgba(185, 184, 216, 1)" : "none",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mr: 2,
                        }}
                    >
                        {links.icon}
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: "#fff",
                                fontWeight: "500",
                                fontStyle: "normal",
                                fontSize: "14px",
                                lineHeight: "22px",
                                textTransform: "none",
                            }}
                        >
                            {links.title}
                        </Typography>
                    </Box>
                </Button>
            </Box>
        );
    }
    return (
        <Box m={1}>
            <DarkTooltip
                arrow
                title={links.title}
                placement="right"
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, 30],
                                },
                            },
                        ],
                    },
                }}
            >
                <IconButton
                    sx={{
                        color: "#fff",
                        textTransform: "none",
                        "&:hover": {
                            background: isSelected ? "none" : "auto",
                        },
                    }}
                >
                    {links.icon}
                </IconButton>
            </DarkTooltip>
        </Box>
    );
};

export const NavLinkWithChildren: React.FC<NavLinkWithChildrenProps> = ({ isOpen, links, pathname }) => {
    const [open, setOpen] = useState(false);
    const isSelected = links?.children?.some((el: { path: string; }) =>
        pathname?.toLowerCase()?.includes(el.path?.toLowerCase())
    );

    if (isOpen) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 1,
                }}
            >
                <Button
                    sx={{
                        width: "90%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "5px",
                        background: isSelected ? "rgba(145, 158, 171, 0.12)" : "",
                        border: isSelected ? "1px solid rgba(185, 184, 216, 1)" : "none",
                    }}
                    onClick={() => setOpen(!open)}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {links.icon}
                        <Box sx={{ ml: 2 }}>
                            <Typography
                                sx={{
                                    color: "#fff",
                                    fontWeight: "500",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                    lineHeight: "22px",
                                    textTransform: "none",
                                }}
                            >
                                {links.title}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <KeyboardArrowDownIcon sx={{ color: "#fff", mr: 1 }} />
                    </Box>
                </Button>
                <Collapse in={open} sx={{ width: "100%" }}>
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                        {links.children?.map((el: NavChild, key: Key) => {
                            return (
                                <RenderIf key={key} condition={!el.hide}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            color: "#fff",
                                            mt: 1.5,
                                            ml: 3.5,
                                        }}
                                        key={key}
                                    >
                                        <NavLink
                                            to={el.path}
                                            key={key}
                                            style={{
                                                textDecoration: "none",
                                                color: "#fff",
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                            }}
                                        >
                                            <CircleIcon
                                                sx={{ fontSize: "5px", mr: 3.5 }}
                                                color="inherit"
                                            />
                                            <Typography sx={{ fontSize: "14px" }}>
                                                {el.title}
                                            </Typography>
                                        </NavLink>
                                    </Box>
                                </RenderIf>
                            );
                        })}
                    </Box>
                </Collapse>
            </Box>
        );
    }
    return (
        <>
            <Box m={1}>
                <LinkTooltip
                    arrow
                    title={
                        <Box>
                            {links.children?.map((el: NavChild, key: Key) => {
                                return (
                                    <RenderIf key={key} condition={!el.hide}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                flexDirection: "column",
                                                width: "auto",
                                            }}
                                            key={key}
                                        >
                                            <Button sx={{ textTransform: "none", width: "100%" }}>
                                                <NavLink
                                                    to={el.path}
                                                    style={{ textDecoration: "none", color: "#fff" }}
                                                >
                                                    <Typography
                                                        sx={{ textAlign: "left", fontWeight: 700 }}
                                                    >
                                                        {el.title}
                                                    </Typography>
                                                </NavLink>
                                            </Button>
                                        </Box>
                                    </RenderIf>
                                );
                            })}
                        </Box>
                    }
                    placement="right"
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: "offset",
                                    options: {
                                        offset: [0, 0],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <Box onClick={() => setOpen(!open)}>
                        <IconButton sx={{ color: "#fff" }}>{links.icon}</IconButton>
                    </Box>
                </LinkTooltip>
            </Box>
        </>
    );
};
