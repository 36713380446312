import React from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import ProtectedRoutes from './utils/ProtectedRoutes';
import Dashboard from './pages/Dashboard';
import InitiateAnalysis from './pages/InitiateAnalysis';
import AllAnalysis from './pages/AllAnalysis';
import SettingsPage from './pages/SettingsPage';
import Reports from './pages/Reports';
import { useSelector } from 'react-redux';
import { RootState } from './redux/rootReducer';
import PasswordSetup from './pages/PasswordSetup';



function App() {
  const role = useSelector((state:RootState)=>state.settings.role);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={< Login/>} />
        <Route path="/setup-password" element={<PasswordSetup />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/initiate-analysis" element={<InitiateAnalysis/>} />
          <Route path="/all-analysis" element={<AllAnalysis/>} />
          {role === "admin" && <Route path="/settings" element={<SettingsPage/>} />}
          <Route path="/all-analysis/:reportId" element={<Reports />} />
        </Route>
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
