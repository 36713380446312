import { Box, Button, Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { NavLinksWithoutChildren, NavLinkWithChildren } from "./NavLinks";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { RenderIf } from "../RenderIf";
import { NavParent } from "./NavConfig";

interface NavComponentsProps {
  isOpen: boolean;
  Items: NavParent;
}
const NavComponents: React.FC<NavComponentsProps> = ({ isOpen, Items }) => {
    const [open, setOpen] = useState(true);
    // const groupPermissions = useSelector(rbacSelectors.getGroupPermissions);
    const { pathname } = useLocation();

    return (
        <>
            <Button
                sx={{
                    width: "90%",
                    borderRadius: "8px",
                    background: "rgba(79, 77, 158, 0.2)",
                    padding: "10px",
                    color: "#919EAB",
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize : "12px"
                }}
                onClick={() => setOpen(!open)}
            >
                {Items.title} <KeyboardArrowDownIcon sx={{ color: "#919EAB" }} />
            </Button>
            <Box sx={{ width: "100%" }}>
                <Collapse in={open} sx={{ padding: "12px 0 5px 0" }}>
                    {Items.children.map((items, key) => {
                        return (
                            <RenderIf key={key} condition={!items.hide}>
                                {items.children ? (
                                    <NavLinkWithChildren
                                        isOpen={isOpen}
                                        links={items}
                                        pathname={pathname}
                                    />
                                ) : (
                                    <NavLink
                                        to={items.path}
                                        key={key}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <NavLinksWithoutChildren
                                            isOpen={isOpen}
                                            links={items}
                                            isSelected={pathname?.includes(items.path)}
                                        />
                                    </NavLink>
                                )}
                            </RenderIf>
                        )
                    })}
                </Collapse>
            </Box>
        </>
    );
};

export default NavComponents;
