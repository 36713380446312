import { Button, CircularProgress, TableCell, TableRow } from "@mui/material"
import { UserDetails } from "../../redux/types"
import { Delete, SendTimeExtension } from "@mui/icons-material"
import { notify } from "../../context/notificationService";
import { getUsersList, removeUser, resendUserInvite } from "../../api/apiCalls";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setUsersList } from "../../redux/slices/userManagementSlice";

const UserSettingsRow: React.FC<UserDetails> = ({username,role,createdAt,verified})=>{
  const dispatch = useDispatch();
  const [sendingInvite,setSendingInvite]= useState(false);
  const [deletingUser,setDeletingUser] = useState(false);

  const fetchUsersList = async()=>{
    try {
      const users = await getUsersList();
      dispatch(setUsersList(users));
    } catch (error) {
      console.log("error",error);
    }
  }

  const handleResendInvite = async()=>{
    try {
      setSendingInvite(true)
      const resp = await resendUserInvite(username);
      notify(resp.message,"success");
    } catch (error) {
      console.log("error",error);
    }finally{
      setSendingInvite(false);
    }
  }

  const handleRemoveUser = async()=>{
    try {
      setDeletingUser(true);
      const resp = await removeUser(username);
      notify(resp.message,"success");
    } catch (error) {
      console.log("error removing user",error)
    }finally{
      setDeletingUser(false);
      await fetchUsersList();
    }
  }

  return <TableRow>
  <TableCell sx={{textWrap:"nowrap"}}>
    {username}
  </TableCell>
  <TableCell sx={{textTransform:"capitalize"}}>
    {role}
  </TableCell>
  <TableCell>
  {new Date(createdAt).toLocaleString()}
  </TableCell>
  <TableCell>
    {verified ? "verified":"pending"}
  </TableCell>
  <TableCell sx={{display:"flex",justifyContent:"flex-end",gap:"16px", minHeight:"32px"}}>
    {!verified && <Button startIcon={sendingInvite ? <CircularProgress size={25} /> : <SendTimeExtension />} onClick={handleResendInvite} disabled={sendingInvite}>Invite again</Button>}
    {role !=="admin" && <Button startIcon={deletingUser ? <CircularProgress size={25} /> : <Delete />} onClick={handleRemoveUser} disabled={deletingUser}>Delete</Button>}
  </TableCell>
</TableRow>
}

export default UserSettingsRow;