import { Box, Button, Typography } from "@mui/material"
import LoginPageDispImg from "../assets/images/loginPageDispImg.png";
import PasswordField from "../components/PasswordField";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { notify } from "../context/notificationService";
import { updateUserPassword, verifyUpdatePasswordUrl } from "../api/apiCalls";
import { WarningRounded } from "@mui/icons-material";
import FullScreenLoader from "../components/loaders/FullScreenLoader";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import { useDispatch } from "react-redux";
import { clearToken } from "../redux/slices/userSlice";
import { AxiosError } from "axios";


const PasswordSetup = ()=>{
  const loginToken = useSelector((state:RootState)=>state.user.token);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // Extract the token query parameter
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token") || "";
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [password,setPassword] = useState("");
  const [passwordMatch,setPasswordMatch] = useState("");
  const [loading,setLoading]= useState(false);
  const [validatingUrl,setValidatingUrl] = useState(true);
  const [text,setText] = useState("");

  const verifyToken = async()=>{
    try {
      const data = await verifyUpdatePasswordUrl(token)
      console.log("verification data",data);
    } catch (error) {
      console.log("err",error);
      if( error instanceof AxiosError && error.status === 400){
        setText("Invalid or expired password setup url!");
      }else{
        setText("something went wrong!")
      }
    }finally{
      setValidatingUrl(false);
    }
  }

  useEffect(()=>{
    if(!token){
      setText("Invalid password setup url!")
    }else if(token){
      verifyToken();
    }
  },[token])
  // Validate form inputs
  const validate = (fieldsToValidate: string[]) => {
    let errors = [...formErrors]; // Initialize errors as an array

    if (fieldsToValidate.includes('password')) {
      const passwordError = validatePassword(password);
      errors = errors.filter(error => !error.includes('Password'));
      if (passwordError) {
        errors.push(passwordError);
      }
    }

    if(fieldsToValidate.includes('passwordMatch')){
      errors = errors.filter(error => !error.includes('match'));
      if(passwordMatch !== password){
        errors.push("password and confirm password did not match")
      }
    }

    setFormErrors(errors); // Set error messages
    return errors.length === 0; // Return true if no errors
  };

  // Function to validate password
  const validatePassword = (password: string) => {
    if (!password) {
      return 'Password is required';
    } else if (password.length < 6) {
      return 'Password must be at least 6 characters';
    }
    return null; // No error
  };

  const handleUpdatePassword = async()=>{
    if(!validate(["password","passwordMatch"])){
      notify(password ? formErrors.join(", ") : "password is required","warning");
      return;
    }
    try {
      setLoading(true);
      const resp = await updateUserPassword(token,password);
      notify(resp.message,"success");
      if(loginToken){
        dispatch(clearToken());
      }
      navigate("/login",{replace:true});
    } catch (error) {
      console.log("err",error);
      if( error instanceof AxiosError){
        notify(error.response?.data?.error?.message || "something went wrong","error");
      }else{
        notify("something went wrong","error");
      }
    }finally{
      setLoading(false);
    }
  }
  return <>{(loading || validatingUrl) && <FullScreenLoader />}<Box>{(text === "" && !validatingUrl) ? <Box sx={{ display: 'grid', height: '100vh', gridTemplateColumns: '1.5fr 1fr', }}><Box sx={{ display: 'flex', justifyContent: 'center', alignItems:'center', backgroundColor: 'primary.main' }}>
    <img src={LoginPageDispImg} alt="Bank Statement Analysis" style={{background: 'transparent', objectFit:'contain', width:'450px',height:'450px'}} />
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' , padding: '100px', gap: '24px'}}>
      <Typography variant="h4" component="h1" sx={{color: 'secondary.main', fontWeight: 700, textAlign: 'left', fontSize:'32px'}}>Create password</Typography>
      <Typography variant="body1" component="p" sx={{color: '#637381', fontWeight: 400, textAlign: 'left', fontSize:16}}>Please create a new password you would like to use to login to your account.</Typography>
      <PasswordField  sx={{borderRadius: '8px'}}
        name="password" onChange={(e)=>setPassword(e.target.value)}
        error={formErrors?.some(error => error.includes('Password'))}
        value={password}
        onBlur={() => validate(['password'])}
      />
      <PasswordField  sx={{borderRadius: '8px'}}
        labelName="Re enter password"
        name="Re enter password" onChange={(e)=>setPasswordMatch(e.target.value)}
        error={formErrors?.some(error => error.includes('match'))}
        value={passwordMatch}
        onBlur={() => validate(['passwordMatch'])}
      />
      {formErrors.length > 0 && (
        <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center',mt:"12px"}}>
          <WarningRounded sx={{color: '#D76662'}} fontSize="small"/>
          <Typography sx={{ color: '#D76662', fontSize: '12px' }}>
            {formErrors.join(', ') + '.'}
          </Typography>
        </Box>
      )}
      <Button variant="contained"
        sx={{backgroundColor: formErrors.length === 0 ? 'primary.main': '#919EAB33', color: formErrors.length === 0 ? 'customColors.white':'#919EABCC', height: '48px', fontWeight: 700, textTransform: 'none'}}
        disabled={(password && formErrors.length === 0) ? false : true }
        onClick={handleUpdatePassword}
        >
          Create new password
        </Button>
    </Box>
  </Box>: <Typography fontSize={18}>{text}</Typography>}</Box>
  </>
}

export default PasswordSetup;