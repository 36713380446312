import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, TextField, Typography } from "@mui/material"
import { useState } from "react";
import PasswordField from "../PasswordField";
import { ExpandMore, WarningRounded } from "@mui/icons-material";
import { useSnackbar } from "../../context/SnackbarProvider";
import { updateCustomerDetails } from "../../api/apiCalls";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { useDispatch } from "react-redux";
import { updateCallbackUrl, updatePrecisaCredentials } from "../../redux/slices/settingsSlice";

const AnalysisSettings = ()=> {
  const precisaUsername = useSelector((state:RootState)=>state.settings.precisaUsername);
  const precisaPassword = useSelector((state:RootState)=>state.settings.precisaPassword);
  const analysisCallback = useSelector((state:RootState)=>state.settings.callbackUrl);
  const {showNotification} = useSnackbar();
  const dispatch = useDispatch();
  const [username,setUsername] = useState(precisaUsername);
  const [password,setPassword] = useState(precisaPassword);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [callbackError,setCallbackError] = useState("");
  const [callbackUrl,setCallbackUrl] = useState(analysisCallback);
  const [loading,setLoading] = useState(false);
  const [loadingText,setLoadingText] = useState("");


  const validate = (fieldsToValidate: string[]) => {
    let errors = [...formErrors];
    if (fieldsToValidate.includes('username')) {
      const usernameError = validateUsername(username);
      errors = errors.filter(error => !error.includes('Username'));
      if (usernameError) {
        errors.push(usernameError);
      }
    }

    if (fieldsToValidate.includes('password')) {
      const passwordError = validatePassword(password);
      errors = errors.filter(error => !error.includes('Password'));
      if (passwordError) {
        errors.push(passwordError);
      }
    }

    setFormErrors(errors);
    return errors.length === 0;
  };

  const validateUsername = (username: string) => {
    if (!username) {
      return 'Username is required';
    }
    if (!(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(username))) {
      return 'Username must be a valid email address';
    }
    return null;
  };

  const validatePassword = (password: string) => {
    if (!password) {
      return 'Password is required';
    } else if (password.length < 6) {
      return 'Password must be at least 6 characters';
    }
    return null;
  };

  function validateCallbackUrl() {
    const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9_-]+)(\.[a-zA-Z]{2,})(\/[^\s]*)?$/;
    if(callbackUrl === ""){
      setCallbackError("Callback url is required")
      return;
    }
    if(!(urlRegex.test(callbackUrl))){
      setCallbackError('Invalid callback url');
      return;
    };
    setCallbackError("");
  }

  const handleAuthUpdate = async() => {
    const isValid = validate(["username","password"])

    if(!isValid){
      showNotification(formErrors.join(", ") || "Please fill all the details correctly","warning");
      return;
    }
    try {
      setLoadingText("Updating analysis credentials...")
      setLoading(true);
      const resp = await updateCustomerDetails({precisaUsername:username,precisaPassword:password});
      showNotification("Analytics credentials updated","success")
      if(resp?.updatedFields?.precisaUsername && resp?.updatedFields?.precisaPassword){
        dispatch(updatePrecisaCredentials({precisaUsername: resp.updatedFields.precisaUsername, precisaPassword: resp.updatedFields.precisaPassword}))
      }
    } catch (error) {
      console.log("error updating analytics credentials",error)
    }finally{
      setFormErrors([]);
      setLoading(false);
      setLoadingText("");
    }
  }
  const handleCallbackUpdate = async() => {
    if(!callbackUrl){
      setCallbackError("Callback url is required");
      showNotification("Callback url is required","warning");
      return;
    }
    if(callbackError){
      showNotification(callbackError,"warning");
      return;
    }
    try {
      setLoadingText("Updating callback url...")
      setLoading(true);
      const resp = await updateCustomerDetails({callbackUrl});
      showNotification("Callback url updated","success")
      if(resp?.updatedFields?.callbackUrl){
        dispatch(updateCallbackUrl(resp.updatedFields.callbackUrl))
      }
    } catch (error) {
      console.log("error updating callback url",error)
    }finally{
      setCallbackError("");
      setLoading(false);
      setLoadingText("")
    }
  }

  return (
    <Box sx={{mt:"16px",position:"relative"}}>
      {loading &&
      <Box sx={{position:"absolute",top:"0",zIndex:10, minHeight:"60vh", display:"flex", justifyContent:"center", alignItems:"center",width:"100%"}}>
      <CircularProgress color="primary" size={50} />
      <Typography sx={{ml:"4px"}}>{loadingText}</Typography>
      </Box>}
      <Accordion sx={{padding:"8px","&.MuiAccordion-root":{
            borderRadius:"12px",
            marginY:"16px"
          },"&.MuiAccordion-root::before":{
          display:"none"
        }}}
        defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
          >
          Authentication Setup
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{display:"grid", gridTemplateColumns:"1fr 1fr", gap:"20px"}}>
          <TextField  label="username" variant="outlined" name="username" value={username} type="email" sx={{label: { color: "#919EAB" }, borderRadius: '12px'}}
          error={formErrors.some(error => error.includes('Username'))}
          onChange={(e)=>setUsername(e.target.value)}
          onBlur={() => validate(['username'])}
          />
          <PasswordField  sx={{borderRadius: '8px'}}
            name="password" onChange={(e)=>setPassword(e.target.value)}
            error={formErrors.some(error => error.includes('Password'))}
            value={password}
            onBlur={() => validate(['password'])}
          />
        </Box>
        <Box>
        {formErrors.length > 0 && (
        <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center',mt:"12px"}}>
          <WarningRounded sx={{color: '#D76662'}} fontSize="small"/>
          <Typography sx={{ color: '#D76662', fontSize: '12px' }}>
            {formErrors.join(', ') + '.'}
          </Typography>
        </Box>
      )}
        </Box>
        </AccordionDetails>
        <AccordionActions sx={{mt:"-12px",px:"16px"}}>
          <Button variant="contained" onClick={handleAuthUpdate}>Update</Button>
        </AccordionActions>
      </Accordion>
      <Accordion sx={{padding:"8px","&.MuiAccordion-root":{
        borderRadius:"12px",
        marginY:"16px"
      },"&.MuiAccordion-root::before":{
          display:"none",
        }}} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2-content"
          id="panel2-header"
          >
          Callback Setup
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{display:"grid", gap:"20px"}}>
          <TextField  label="callback url" variant="outlined" name="callbackurl" value={callbackUrl} type="url"
            sx={{label: { color: "#919EAB" }, borderRadius: '12px'}}
            onChange={(e)=>setCallbackUrl(e.target.value)}
            error={(callbackError.length > 0)}
            onBlur={()=>{validateCallbackUrl()}}
            helperText={
              callbackError.length > 0 ? (
                <Box component="span" sx={{ color: 'error.main',margin:"8px -14px", display:"flex", gap:"4px", alignItems:"center"}}>
                  <WarningRounded sx={{color: '#D76662'}} fontSize="small"/>
                  {callbackError}
                </Box>
              ) : (
                ""
              )
            }
          />
        </Box>
        </AccordionDetails>
        <AccordionActions sx={{mt:"-12px",px:"16px"}}>
          <Button variant="contained" onClick={handleCallbackUpdate}>Update</Button>
        </AccordionActions>
      </Accordion>
    </Box>
  )
}

export default AnalysisSettings;