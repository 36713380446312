import { Download } from "@mui/icons-material";
import { Box, Button, CircularProgress, Tab, Tabs, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import CustomTabPanel from "../components/CustomTabPanel";
import AllTransactions from "../components/reports-page/AllTransactions";
import { downLoadBSAReport, getAllTransactions, getFraudIndicators, getTransactionsTotalSummary } from "../api/apiCalls";
import { Link, Navigate, useParams } from "react-router-dom";
import { downloadReportFiles } from "../utils/utils";
import { notify } from "../context/notificationService";
import { useDispatch } from "react-redux";
import { clearTransactionsFilter, setInitialReportState, setIrregularitiesData, setReportId, setTransactionsActiveType, setTransactionsData, setTransactionsReady, setTransactionsTotalSummary } from "../redux/slices/reportSlice";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import FraudIndicators from "../components/reports-page/FraudIndicators";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabs = {"Summary":0,"Transactions":1,"Fraud Indicators":2,"UPI Txns Analysis":3,"AML Analysis":4,"Monthly Summary":5,"Daily Balance":6}; //tabs with index used in mapping tab panel

const Reports = ()=>{
  const [currentTab, setCurrentTab] = useState(1);
  const { reportId } = useParams<{ reportId: string }>();
  const limit = useSelector((state:RootState)=>state.report.transactions.appliedFilters.limit);
  const noOfFraudIndicators = useSelector((state:RootState)=>state.report.irregularities.noOfFraudIndicators);

  const [downloadingReport,setDownloadingReport] = useState(false);
  const [transactionsReady,setTransactionsReadyStatus] = useState(false);
  const [fraudIndicatorsReady,setFraudIndicatorsReady] = useState(false);
  const dispatch = useDispatch();



  const fetchAllTransactionsData = async(reportId:string)=>{
    try {
      dispatch(clearTransactionsFilter())
      dispatch(setTransactionsActiveType(0));
      const data = await getAllTransactions({reportId,page: 1,limit,sort: -1});
      dispatch(setTransactionsData(data));
    } catch (error) {
      console.log("error",error);
    }finally{
      setTransactionsReadyStatus(true);
      dispatch(setTransactionsReady(true));
    }
  }

  const fetchTransactionsSummary = async(reportId:string)=>{
    try {
      const data = await getTransactionsTotalSummary(reportId);
      dispatch(setTransactionsTotalSummary(data));
    } catch (error) {
      console.log("error",error);
    }finally{
    }
  }

  const fetchFraudIndicators = async(reportId:string)=>{
    try {
      const resp = await getFraudIndicators(reportId);
      dispatch(setIrregularitiesData(resp.data));
    } catch (error) {
      console.log("error",error);
    }finally{
      setFraudIndicatorsReady(true);
    }
  }

  useEffect(()=>{
    dispatch(setInitialReportState());
    if(reportId){
      dispatch(setReportId(reportId));
      fetchAllTransactionsData(reportId);
    }
  },[reportId])

  useEffect(()=>{
    if(reportId && transactionsReady){
      fetchTransactionsSummary(reportId);
      fetchFraudIndicators(reportId);

    }
  },[reportId,transactionsReady])

  if(!reportId){
    return <Navigate to="/all-analysis" replace />
  }

  const handleChange = (event: React.SyntheticEvent, newTab: number) => {
    setCurrentTab(newTab);
  };

  const handleDownloadReport = async()=>{
    try {
      setDownloadingReport(true);
      const resp = await downLoadBSAReport({reportId,fileType:"json and excel"});
      if(resp?.result?.json){
        await downloadReportFiles(resp.result.json,'json',reportId);
      }
      if(resp?.result?.excel){
        await downloadReportFiles(resp.result.excel,'excel',reportId);
      }
    } catch (error) {
      console.log("error downloading file",error);
      notify(`Error downloading report for ${reportId}`);
    }finally{
      setDownloadingReport(false)
    }
  }
  return (
    <Box sx={{backgroundColor:"customColors.white", display:"flex", flexDirection:"column",height:"100vh"}}>
      <Box sx={{display:"flex",justifyContent:"space-between",padding:"32px", alignItems:"center"}}>
        <Box>
          <Typography variant="h4" fontSize={24} lineHeight="36px" fontWeight={700}>
            Bank Statement Analysis
          </Typography>
          <Box sx={{display:"flex", alignItems:"center", gap:"8px", fontSize:"14px", fontWeight:400,mt:"8px"}}>
            <Link to="/dashboard" style={{textDecoration:"none", color:"#212B36"}}>Dashboard</Link>
            <Box sx={{backgroundColor:"#919EAB", borderRadius:"100%", height:"4px", width:"4px"}}></Box>
            <Link to="/all-analysis" style={{textDecoration:"none", color:"#212B36"}}>All Reports</Link>
            <Box sx={{backgroundColor:"#919EAB", borderRadius:"100%", height:"4px", width:"4px"}}></Box>
            <Typography fontSize={14} fontWeight={400} color="#919EAB">{reportId}</Typography>
          </Box>
        </Box>
        <Box>
          {
            downloadingReport ? <Box sx={{fontWeight:"500",color:"customColors.blue-600", display:"flex",gap:"8px", borderColor:"customColors.blue-600", border:"1px solid",borderRadius:"8px", padding:"4px 12px", alignItems:"center"}}><CircularProgress size={25}/> Downloading report</Box>:
            <Button variant="outlined" startIcon={<Download />} sx={{fontWeight:"700",color:"customColors.blue-600"}} onClick={handleDownloadReport}>
              Download report
            </Button>
          }
        </Box>
      </Box>
      <Box sx={{borderBottom:2, borderColor:"divider", paddingX:"12px"}}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="report">
          {Object.keys(tabs).map((tabName,index)=> {return(
            <Tab label={tabName} {...a11yProps(index)} sx={{color:"customColors.gray-200", textTransform:"none", fontWeight:"600"}} key={index}
             iconPosition="end" icon={index ===2 ? <Box sx={{height:"20px",width:"20px",display:"flex",alignItems:"center",
              justifyContent:"center", fontSize:"12px", fontWeight:700, borderRadius:"50%",
              backgroundColor: (noOfFraudIndicators === 0) ? "#919EAB33": "#D76662", color:(noOfFraudIndicators === 0) ? "customColors.gray-200":"customColors.white"
              }}>
              {noOfFraudIndicators}
            </Box>: <></>}/>
          )})}
        </Tabs>
      </Box>
      <Box sx={{backgroundColor:"rgb(237, 237, 245)", flexGrow:1, overflowY:"scroll", padding:"20px 32px"}}>
       <CustomTabPanel value={currentTab} index={tabs["Summary"]}>
        {/* TO DO: Summary component */}
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={tabs["Transactions"]}>
          {!transactionsReady ? <Box sx={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}><CircularProgress /></Box> :  <AllTransactions reportId={reportId}/>}
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={tabs["Fraud Indicators"]}>
          {!fraudIndicatorsReady ? <Box sx={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}><CircularProgress /></Box> :  <FraudIndicators />}
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={tabs["UPI Txns Analysis"]}>
          {/* TO DO: UPI Txns Analysis component */}
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={tabs["AML Analysis"]}>
          {/* TO DO: AML Analysis component */}
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={tabs["Monthly Summary"]}>
          {/* TO DO: Monthly Summary component */}
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={tabs["Daily Balance"]}>
          {/* TO DO: Daily Balance componen */}
        </CustomTabPanel>
      </Box>
    </Box>
  )
}

export default Reports;