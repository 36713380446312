import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#212B36',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#212B36',
        padding : "15px",
        fontSize : "14px",
        fontWeight : 700
    },
}));

export const LinkTooltip = styled(({ className, ...props } : TooltipProps )=> (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#212B36',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#212B36",
        color: '#fff',
        maxWidth: 300,
        fontSize: '14px',
        fontWeight : 700
    },
}));
interface CustomTooltipProps extends TooltipProps {
    width?: number | string,
    background?: string,
    padding?: number | string,
    fontSize?: number | string,
    fontWeight?: number | string,
    color?:string,
    border?:string
}

export const CustomTooltip = styled(({ className, ...props }: CustomTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ width = 'none', background = '#212B36', padding = '8px', fontSize = '14px', fontWeight = 500, color = '#FFFFFF', border='1px solid #212B36' }) => ({
[`& .${tooltipClasses.tooltip}`]: 
{maxWidth: width, background: background, padding: padding, fontSize: fontSize, fontWeight: fontWeight, color: color, border: border,},}));
