import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DashboardState {
  currentDayFilter: string;
}

const initialState: DashboardState = {
  currentDayFilter: "Today",
}
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setCurrentDayFilter: (state, action: PayloadAction<string>) => {
      state.currentDayFilter = action.payload;
    }
  },
});

export const {setCurrentDayFilter } = dashboardSlice.actions;
export default dashboardSlice.reducer;
