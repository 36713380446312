import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDetails } from "../types";

interface UserManagementState {
  usersList: UserDetails[];
  loadingUsersList:boolean;
}

const initialState: UserManagementState = {
  usersList:[],
  loadingUsersList:false,
}
const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setLoadingUsersList: (state, action: PayloadAction<boolean>) => {
      state.loadingUsersList = action.payload;
    },
    setUsersList:(state, action: PayloadAction<UserDetails[]>) => {
      state.usersList = action.payload;
    }
  },
});

export const {setLoadingUsersList, setUsersList } = userManagementSlice.actions;
export default userManagementSlice.reducer;
