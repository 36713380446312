import { combineReducers } from "redux";
import userReducer from "./slices/userSlice";
import initiateAnalysisReducer from "./slices/initiateAnalysisSlice";
import dashboardReducer from "./slices/dashboardSlice";
import settingsReducer from "./slices/settingsSlice";
import allAnalysisReducer from "./slices/allAnalysisSlice";
import formStateReducer from "./slices/formStateSlice";
import reportPageStateReducer from "./slices/reportSlice";
import userManagementReducer from "./slices/userManagementSlice";
const rootReducer = combineReducers({
  user: userReducer,
  initiateAnalysis: initiateAnalysisReducer,
  dashboard: dashboardReducer,
  settings: settingsReducer,
  allAnalysis: allAnalysisReducer,
  forms: formStateReducer,
  report: reportPageStateReducer,
  userManagement: userManagementReducer,
});

export default rootReducer;

// Define RootState type
export type RootState = ReturnType<typeof rootReducer>;
