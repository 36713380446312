import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { downLoadBSAReport } from "../../api/apiCalls";
import { useState } from "react";
import CheckReportStatusButton from "../buttons/CheckReportStatusButton";
import { Link } from "react-router-dom";

interface ReportListCardProps {
  reportId: string;
  createdAt: string;
  status: string;
}
const ReportListCard: React.FC<ReportListCardProps> = ({reportId, createdAt, status}) => {
  const [loading,setLoading] = useState(false);
  const downloadFile = async (url:string,fileType: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = downloadUrl;
      const date = new Date().toLocaleDateString();
      a.download = fileType === 'json'
      ? `${reportId}_${date}.json`
      : `${reportId}_${date}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  const handleDownloadReport = async() => {
    setLoading(true);
    try {
      const resp = await downLoadBSAReport({reportId,fileType:'json and excel'});
      if(resp?.result?.json){
        await downloadFile(resp.result.json,'json');
      }
      if(resp?.result?.excel){
        await downloadFile(resp.result.excel,'excel');
      }
    } catch (error) {
      console.log("error downloading report",error);
    }finally{
      setLoading(false);
    }
  }
  return (
    <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', p: 2, alignItems: 'center', fontSize: '14px', borderBottom:'2px solid #EDEDF5'}}>
      <Typography>
        {reportId}
      </Typography>
      <Typography>
        {new Date(createdAt).toLocaleString()}
      </Typography>
      <Typography>
        {status}
      </Typography>
      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        {loading ? <CircularProgress sx={{mr:'40px'}} size={20} /> :
        status === "IN PROGRESS" ? <CheckReportStatusButton  reportId={reportId}/> :
        <Box sx={{display:"flex", alignItems:"center",gap:"12px"}}>
        <Link to={`/all-analysis/${reportId}`} style={{textDecoration:"none", color:"black"}}>View report</Link>
        <Button sx={{borderRadius: '8px',color: status === "IN PROGRESS" ? "#99cc33":"primary.main"}} onClick={handleDownloadReport}>
           Download report
        </Button>
        </Box>
        }
      </Box>
    </Box>
  )
}

export default ReportListCard;